import Types from "./Types";

const INIT_STATE = {
  articles: {},
};

export function termsAndConditionsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CONDITION_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case Types.GET_TERMS_SUCCESS:
      return {
        ...state,
        articles: payload?.data?.articles, 
      };

      case Types.GET_PRIVACY_SUCCESS:
      return {
        ...state,
        articles: payload?.data?.articles, 
      };
    default:
      return state;
  }
}
