import { GetBooksSuccess, BooksLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetBooksRequest = () => {
  return async (dispatch) => {

    const requestData = {
      isLoading: BooksLoading,
      successFunction: GetBooksSuccess,
      loadingType: "list books",
      url: "/books",
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
