import React, { useState } from "react";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AboutUsArticle = ({ article }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // console.log(article);
  const handleReadMore = () => {
    // Navigate to /about-us/ when readMore button is clicked
    navigate("/about-us");
  };
  const isWeb = useMediaQuery("(min-width: 768px)");


  return (
    <Stack
      sx={{
        justifyContent: "space-between",
        height: "100%",
        gap: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography fontSize={{ xs: "22px", md: "30px", lg: "40px" }} fontWeight="700" gutterBottom>
          {t("home.aboutUs.title")}
        </Typography>
        <Typography fontSize={{ xs: "22px", md: "32px" }} fontWeight="500" gutterBottom>
          {article.name}
        </Typography>
        <Box
          sx={{
            fontSize: { xs: "14px", md: "18px", lg: "24px" },
            fontWeight: "500",
          }}
        >
           <div dangerouslySetInnerHTML={{ __html: article.article }} />
        </Box>
      </Box>
     
      {isWeb && (
        <Button
        variant="outlined"
        color="primary"
        sx={{
          alignSelf: "flex-start",
          borderRadius: 21,
        }}
        onClick={handleReadMore}
      >
         {t("buttons.readMore")}
      </Button>
      )}
    </Stack>
  );
};

export default AboutUsArticle;
