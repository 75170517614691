import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Box, Typography, Stack, IconButton, useMediaQuery } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { ToggleSuccessModal } from "services/modules/modals/Actions";
import successIcon from "../../../../assets/images/success-icon.svg";

const SuccessModal = ({ t }) => {
  const { isOpen, message } = useSelector((state) => state.modals.success);
  const dispatch = useDispatch();
  const { blackTwo } = useThemePalette();
  const isWeb = useMediaQuery("(min-width: 768px)");


  const handleClose = () => {
    dispatch(ToggleSuccessModal({ isOpen: false }));
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isWeb ? "40%" : "100%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "40px",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <img src={successIcon} alt="Success" style={{ width: "150px", height: "150px" }} />
          <Typography  fontSize={{ xs: "18px", sm: "20px" }} textAlign="center" fontWeight="500" color={blackTwo}>
            {message}
          </Typography>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
