import axios from "axios";
import { fileUploadLoading, fileUploadOnServerLoading, fileUploadSuccess } from "./Actions";
// import Cookies from "js-cookie";
import { reduxRequest } from "common/utils/reduxRequest";
import { store } from "services/store";
import { version } from "../utils/constant";

// const token = Cookies.get("token");
export const getPresignedUrlRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadLoading,
    // successFunction: fileUploadSuccess,
    loadingType: "upload",
    url: `/presigned`,
    method: "get",
    data: variables.body,
    action: (data) => {
      const res = data?.data;
      store.dispatch(
        uploadRequest({
          put_url: res?.put_url,
          file: variables.body.file,
          fileUrl: res?.file_url,
          updateData: variables?.updateData,
        })
      );
    },
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const uploadRequest = ({ ...variables }) => {
  const state = store.getState();
  const token = state.auth.userData.token;
  const requestData = {
    ...variables,
    isLoading: fileUploadOnServerLoading,
    loadingType: "uploadOnServer",
    url: variables.put_url,
    data: variables.file,
    method: "put",
    action: () => {
      store.dispatch(fileUploadSuccess(variables?.fileUrl));
      variables?.updateData && variables?.updateData(variables.fileUrl);
      // set token in headers after uploading success
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      delete axios.defaults.headers.common["Content-Type"];
    },
  };
  delete axios.defaults.headers.common["Authorization"];
  axios.defaults.headers.common["Content-Type"] = variables?.file?.type;
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
