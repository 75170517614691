import Types from "./Types";
import setAuthToken from "../utils/handel_api";

const INIT_STATE = {
  services: [],
};

export function servicesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SERVICES_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case Types.GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: payload?.data?.services, 
      };

    case Types.SERVICE_REQUEST_SUCCESS:
  
      return {
        ...state,
        isLoading: false,
        service_request: payload?.data?.service_request,
      };
      
    default:
      return state;
      }
  }
