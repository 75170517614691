import Types from "./Types";

const INIT_STATE = {
  partners: [],
};

export function partnersReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.PARTNERS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case Types.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: payload?.data?.partners, 
      };
    default:
      return state;
  }
}
