import axios from "axios";
import Types from "./Types";

const initialState = {
  lang: "en",
};

// const languageReducer = (state = initialState, action) => {
//   const { type, payload } = action;

//   switch (type) {
//     case Types.SET_LANGUAGE:
//       return {
//         ...state,
//         lang: payload,
//       };
//     default:
//       return state;
//   }
// };
export function languageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_LANGUAGE: {
      state.lang = payload;
      axios.defaults.headers.common["Accept-Language"] = payload;
      return {
        lang: payload,
      };
    }
    default:
      return state;
  }
}

export default languageReducer;
