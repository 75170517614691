import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AboutUsTemplate from "templates/aboutus/AboutUs.Template";

const AboutUsPage = ({ t }) => {
  const dispatch = useDispatch();

  return (
    <>
      
        <AboutUsTemplate t={t}/>
  
    </>
  );
};

export default withTranslation()(AboutUsPage);
