import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Stack, Typography, useMediaQuery, Divider, Button } from "@mui/material";
import { GetContactUsRequest } from "services/modules/contactUs";
import { images } from "assets/AssetHelper";
import { useTranslation } from "react-i18next";
import { contactUsStyles } from "./Styles";
import ShowComponentWhen from "components/Atoms/SharedComponents/ShowComponentWhen";
import Loader from "components/Atoms/Loader/Loader";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { contacts } = useSelector((state) => state.contactUs);
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(min-width: 768px)");
  const load = useSelector((state) => state.auth.load);

  useEffect(() => {
    dispatch(GetContactUsRequest());
  }, [dispatch]);

  const socialMediaContacts = contacts.filter((contact) =>
    ["twitter", "Instagram", "Linkedin", "Whatsapp"].includes(contact.name)
  );

  const otherContacts = contacts
    .filter((contact) => !["twitter", "Instagram", "Linkedin", "Whatsapp"].includes(contact.name))
    .sort((a, b) => {
      const order = { Address: 1, Email: 2, Phone: 3 };
      return order[a.name] - order[b.name];
    });

  return (
    <>
      <Loader open={load?.loading} />
      <Container sx={contactUsStyles.container}>
        <Stack sx={contactUsStyles.stack}>
          <ShowComponentWhen
            when={!isSmallScreen}
            show={<Typography sx={contactUsStyles.title}>{t("header.contactUs.title")}</Typography>}
          />
          <ShowComponentWhen
            when={!isSmallScreen}
            show={
              <Container sx={contactUsStyles.cardContainer}>
                <Box sx={contactUsStyles.card}>
                  <Stack component="figure" sx={contactUsStyles.imageContainer}>
                    <Box
                      component="img"
                      src={images.ContactUsImg}
                      alt="contactus-img"
                      sx={contactUsStyles.image}
                    />
                  </Stack>
                  <Stack sx={contactUsStyles.content}>
                    <Typography sx={contactUsStyles.header}>{t("contactUs.header")}</Typography>
                    {otherContacts.map((contact) => (
                      <Box key={contact?.id} sx={contactUsStyles.contactItem}>
                        <Box
                          component="img"
                          src={contact?.images}
                          alt={contact?.name}
                          sx={contactUsStyles.contactImage}
                        />
                        <Typography sx={contactUsStyles.contactText}>{contact?.value}</Typography>
                      </Box>
                    ))}
                    <Stack justifyContent="space-between" mt="20px" gap="20px">
                      <Typography sx={contactUsStyles.subHeader}>
                        {t("contactUs.subHeader")}
                      </Typography>
                      <Divider sx={contactUsStyles.divider} />

                      <Stack direction="row" gap="24px" sx={{ justifyContent: "flex-start" }}>
                        {socialMediaContacts.map((contact) => (
                          <Button
                            key={contact?.id}
                            component="a"
                            href={contact?.value}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={contactUsStyles.socialIcons}
                          >
                            <Box
                              component="img"
                              src={contact?.images}
                              alt={contact.name}
                              sx={contactUsStyles.socialImage}
                            />
                          </Button>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Container>
            }
          />

          <ShowComponentWhen
            when={isSmallScreen}
            show={
              <>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "20px", lg: "34px" },
                    width: { xs: "100%", sm: "45%" },
                  }}
                >
                  <Typography sx={contactUsStyles.header}>{t("contactUs.header")}</Typography>
                  {otherContacts.map((contact) => (
                    <Box key={contact?.id} sx={contactUsStyles.contactItem}>
                      <Box
                        component="img"
                        src={contact?.images}
                        alt={contact?.name}
                        sx={contactUsStyles.contactImage}
                      />
                      <Typography sx={contactUsStyles.contactText}>{contact?.value}</Typography>
                    </Box>
                  ))}

                  <Stack justifyContent="space-between" mt={2} gap="30px">
                    <Typography sx={contactUsStyles.subHeader}>
                      {t("contactUs.subHeader")}
                    </Typography>
                    <Divider sx={contactUsStyles.divider} />

                    <Stack direction="row" gap="24px" sx={{ justifyContent: "flex-start" }}>
                      {socialMediaContacts.map((contact) => (
                        <Button
                          key={contact?.id}
                          component="a"
                          href={contact?.value}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={contactUsStyles.socialIcons}
                        >
                          <Box
                            component="img"
                            src={contact?.images}
                            alt={contact.name}
                            sx={contactUsStyles.socialImage}
                          />
                        </Button>
                      ))}
                    </Stack>
                  </Stack>
                </Box>
                <Stack component="figure" sx={contactUsStyles.imageContainer}>
                  <Box
                    component="img"
                    src={images.ContactUsImg}
                    alt="contactus-img"
                    sx={contactUsStyles.image}
                  />
                </Stack>
              </>
            }
          />
        </Stack>
      </Container>
    </>
  );
};

export default ContactUs;
