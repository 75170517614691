import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useValidationSchemas = () => {
  const { t } = useTranslation();

  // Existing schemas...
  const loginSchema = yup.object().shape({
    phone_number: yup.string().required(t("validation.phone_number.required")),
    password: yup.string().required(t("validation.password.required")),
  });

  const signupSchema = yup.object({
    username: yup.string().required(t("validation.firstname.required")),
    secondname: yup.string().required(t("validation.secondname.required")),
    thirdname: yup.string().required(t("validation.thirdname.required")),
    nickname: yup.string().required(t("validation.nickname.required")),
    phone_number: yup
      .string()
      .required(t("validation.phone_number.required"))
      .matches(/^\d+$/, t("validation.phone_number.valid")),
    email: yup.string().email(t("validation.email.valid")).required(t("validation.email.required")),
    password: yup
      .string()
      .min(8, t("validation.password.min"))
      .max(20, t("validation.password.max"))
      .required(t("validation.password.required")),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], t("validation.password.match"))
      .required(t("validation.confirm_password")),
    terms: yup.boolean().oneOf([true], t("validation.terms")).required(t("validation.terms")),
  });

  const verificationSchema = yup.object({
    otp: yup.string().required(t("validation.otp.required")).length(4, t("validation.otp.length")),
  });

  const phoneValidationSchema = yup.object().shape({
    phone_number: yup.string().required(t("validation.phone_number.required")),
    country_code: yup.string().required(t("validation.country_code.required")),
  });

  const resetPasswordSchema = yup.object({
    password: yup
      .string()
      .min(8, t("validation.password.min"))
      .max(20, t("validation.password.max"))
      .required(t("validation.password.required")),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t("validation.password.match"))
      .required(t("validation.confirm_password")),
  });

  const legalServiceSchema = yup.object({
    message: yup
      .string()
      .max(300, t("validation.description.max"))
      .required(t("validation.description.required")),
    serviceType: yup.string().optional(),
    date: yup.string().required(t("validation.appointment")),
    phone_number: yup.string().optional(),
  });

  const changePasswordSchema = yup.object().shape({
    password_old: yup.string().required(t("validation.password_old.required")),
    password: yup
      .string()
      .min(8, t("validation.password.min"))
      .required(t("validation.password.required")),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t("validation.password.match"))
      .required(t("validation.confirm_password")),
  });

  const careersSchema = yup.object({
    city: yup
      .string()
      .required(t("validation.city.required"))
      .max(100, t("validation.city.max", { max: 100 })),
    area: yup
      .string()
      .required(t("validation.area.required"))
      .max(100, t("validation.area.max", { max: 100 })),
    resume: yup.string().required(t("validation.area.required")),
  });

  return {
    loginSchema,
    signupSchema,
    verificationSchema,
    phoneValidationSchema,
    resetPasswordSchema,
    legalServiceSchema,
    changePasswordSchema,
    careersSchema,
  };
};
