import Types from "./Types";

export const GetLinksSuccess = (payload) => ({
  type: Types.GET_LINKS_SUCCESS,
  payload,
});

export const LinksLoading = (payload) => ({
  type: Types.LINKS_LOADING,
  payload,
});
