import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetPartnersRequest } from "services/modules/partners";
import PartnerCard from "components/Molecules/Home/PartnerCard";
import CustomSlider from "components/Atoms/SharedComponents/CustomSlider";

const Partners = () => {
  const dispatch = useDispatch();
  const { partners } = useSelector((state) => state.partners);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(GetPartnersRequest());
  }, [dispatch]);

  return (
    <>
      <Container sx={{ maxWidth: { xl: "xl", md: "lg" } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 5 }}>
          <Typography fontSize={{ xs: "22px", md: "30px", lg: "40px" }} fontWeight="700">
            {t("home.partners.title")}
          </Typography>
        </Box>
        <CustomSlider>
          {partners.map((partner) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={partner.id}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <PartnerCard partner={partner} />
            </Grid>
          ))}
        </CustomSlider>
      </Container>
    </>
  );
};

export default Partners;
