import React from "react";
import { withTranslation } from "react-i18next";
import Header from "components/Atoms/SharedComponents/Header";
import Careers from "components/Organisms/careers/Careers";


const CareersTemplate = ({ t }) => {
  return (
    <>
      <Header
       title={t("header.careers.title")}
       subtitle={t("header.careers.subtitle")}
      />
      <Careers />
    </>
  );
};

export default withTranslation()(CareersTemplate);