import { withTranslation } from "react-i18next";
import ProfileTemplate from "templates/profile/Profile.Template";

const ProfilePage = ({ t }) => {

  return (
    <>
      
        <ProfileTemplate t={t}/>
  
    </>
  );
};

export default withTranslation()(ProfilePage);
