import Header from "components/Atoms/SharedComponents/Header";
import About from "components/Organisms/aboutus/About";
import React from "react";
import { withTranslation } from "react-i18next";

const AboutUsTemplate = ({ t }) => {
  return (
    <>
    <Header 
     title={t("header.aboutUs.title")}
     subtitle={t("header.aboutUs.subtitle")}
    />
    <About/>
    </> 
  );
}
export default withTranslation()(AboutUsTemplate);
