import {  GetSpecialitiesSuccess, SpecialtiesLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetSpecialtiesRequest = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const requestData = {
      isLoading: SpecialtiesLoading,
      successFunction: GetSpecialitiesSuccess,
      loadingType: "list specialities",
      url: "/specialities",
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
