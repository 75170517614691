import React from "react";
import { Modal, Box, Typography, Stack, Button, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToggleGuestModeModal } from "services/modules/modals/Actions";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import { useTranslation, withTranslation } from "react-i18next";

const GuestModeModal = ({ t }) => {
  const { isOpen, header, subHeader } = useSelector((state) => state.modals.guestMode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isWeb = useMediaQuery("(min-width: 768px)");

  const onClose = () => {
    dispatch(
      ToggleGuestModeModal({
        isOpen: false,
        header: null,
        subHeader: null,
      })
    );
  };

  const handleClick = (path) => {
    navigate(path);
    dispatch(
      ToggleGuestModeModal({
        isOpen: false,
        header: null,
        subHeader: null,
      })
    );
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isWeb ? "40%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 5,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <IconButton sx={closeButtonStyle} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography fontSize={{ xs: "18px", sm: "24px" }} textAlign="center" fontWeight="700">
          {header}
        </Typography>
        <Typography fontSize={{ xs: "16px", sm: "18px" }} textAlign="center" fontWeight="400">
          {subHeader}
        </Typography>
        <Stack direction="column" spacing={2} justifyContent="center">
          <LoadingBtn
            variant="contained"
            sx={{ color: "secondary.main", fontWeight: 500, fontSize: "14px" }}
            onClick={() => handleClick("/login")}
          >
            {t("buttons.login")}
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            sx={{ color: "secondary.main", fontWeight: 500, fontSize: "14px" }}
            onClick={() => handleClick("/signup")}
          >
            {t("buttons.signUp")}
          </LoadingBtn>
        </Stack>
      </Box>
    </Modal>
  );
};

export default withTranslation()(GuestModeModal);
