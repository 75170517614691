import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Button, Container, Stack, IconButton, useMediaQuery, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { icons } from 'assets/AssetHelper';
import LoadingBtn from 'components/Atoms/Loader/LoadingBtn';
import PhoneInputModal from 'components/Atoms/SharedComponents/Modals/PhoneInputModal';
import { ToggleChangePasswordModal, TogglePhoneInputModal } from 'services/modules/modals/Actions';
import { UpdateProfileRequest } from 'services/modules/auth';
import ChangePasswordModal from 'components/Atoms/SharedComponents/Modals/ChangePasswordModal';
import { styles } from './Styles';  
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";

const Profile = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.userData);
    const { t } = useTranslation();
    const isWeb = useMediaQuery("(min-width: 768px)");

    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({ 
        name: '', 
        secondname: '', 
        thirdname: '', 
        nickname: '', 
        email: '' 
    });

    useEffect(() => {
        if (user) {
            setEditedData({ 
                name: user.name || '', 
                secondname: user.secondname || '', 
                thirdname: user.thirdname || '', 
                nickname: user.nickname || '', 
                email: user.email 
            });
        }
    }, [user]);

    const handleChangePhoneNumber = () => {
        dispatch(
            TogglePhoneInputModal({
                isOpen: true,
                header: t("profile.header1"),
                subHeader: t("profile.subHeader1"),
                btnTitle: t("buttons.next"),
                fnToDispatch: UpdateProfileRequest
            })
        );
    };

    const handleChangePassword = () => {
        dispatch(
            ToggleChangePasswordModal({
                isOpen: true
            })
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = () => {
        const action = () => {
            console.log('Profile updated successfully');
        };

        const requestData = {
            body: {
                user: {
                    ...editedData,
                },
            },
            action,
        };

        dispatch(UpdateProfileRequest(requestData));

        setIsEditing(false);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const getFullName = () => {
        return `${editedData.name} ${editedData.secondname} ${editedData.thirdname} ${editedData.nickname}`.trim();
    };

    return (
        <>
            <PhoneInputModal t={t} />
            <ChangePasswordModal t={t} />
            <Container sx={styles.container}>
                {isWeb ? (
                    <Stack direction="row" gap="20px" sx={{ width: '70%' , mt:"100px"}}>
                        <Stack sx={styles.profileSection}>
                            <Box sx={styles.profileSectionHeader}>
                                <Typography variant="h6" fontWeight="700" align="center" color="black">
                                    {t('profile.myProfile')}
                                </Typography>
                            </Box>
                            <Box sx={styles.profileSectionContent}>
                                <Box sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h6" sx={styles.profileTitle}>
                                            {t('profile.name')}
                                        </Typography>
                                        <IconButton onClick={handleEdit}>
                                            <img src={icons.EditIcon} alt="edit" style={styles.editIcon} />
                                        </IconButton>
                                    </Box>
                                    {isEditing ? (
                                        <>
                                                  <Stack direction="row" gap="24px" sx={{ pb: "16px" }}>
                                            <TextFieldInput
                                                fullWidth
                                                name="name"
                                                value={editedData.name}
                                                onChange={handleChange}
                                                label={t('profile.firstname')}
                                                sx={{ mt: 1 }}
                                            />
                                            <TextFieldInput
                                                fullWidth
                                                name="secondname"
                                                value={editedData.secondname}
                                                onChange={handleChange}
                                                label={t('profile.secondname')}
                                                sx={{ mt: 1 }}
                                            />
                                            </Stack>
                                            <Stack direction="row" gap="24px" sx={{ pb: "16px" }}>

                                            <TextFieldInput
                                                fullWidth
                                                name="thirdname"
                                                value={editedData.thirdname}
                                                onChange={handleChange}
                                                label={t('profile.thirdname')}
                                                sx={{ mt: 1 }}
                                            />
                                            <TextFieldInput
                                                fullWidth
                                                name="nickname"
                                                value={editedData.nickname}
                                                onChange={handleChange}
                                                label={t('profile.nickname')}
                                                sx={{ mt: 1 }}
                                            />
                                            </Stack>
                                        </>
                                    ) : (
                                        <Typography sx={styles.profileText}>{getFullName()}</Typography>
                                    )}
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="h6" sx={styles.profileTitle}>
                                        {t('profile.email')}
                                    </Typography>
                                    {isEditing ? (
                                        <TextFieldInput
                                            fullWidth
                                            name="email"
                                            value={editedData.email}
                                            onChange={handleChange}
                                            sx={{ mt: 1 }}
                                        />
                                    ) : (
                                        <Typography sx={styles.profileText}>{user?.email}</Typography>
                                    )}
                                </Box>
                                <Box sx={{ mt: 2, mb: 5 }}>
                                    <Typography variant="h6" sx={styles.profileTitle}>
                                        {t('profile.phone')}
                                    </Typography>
                                    <Typography sx={styles.profileText}>
                                        {user?.phone_number} {user?.country_code}
                                    </Typography>
                                </Box>
                                {isEditing && (
                                    <LoadingBtn onClick={handleSave} fullWidth sx={{ mt: 5 }} variant="contained" color="primary">
                                        {t('buttons.save')}
                                    </LoadingBtn>
                                )}
                            </Box>
                        </Stack>

                        {/* Profile Settings Section */}
                        <Stack sx={styles.settingsSection}>
                            <Box sx={styles.settingsSectionHeader}>
                                <Typography variant="h6" align="center" fontWeight="700" color="black">
                                    {t('profile.settings')}
                                </Typography>
                            </Box>
                            <Box sx={styles.settingsSectionContent}>
                                <Button onClick={handleChangePhoneNumber} sx={styles.button} fullWidth>
                                    {t('profile.changeNumber')}
                                </Button>
                                <Button onClick={handleChangePassword} sx={styles.button} fullWidth>
                                    {t('profile.changePassword')}
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack gap={2} sx={{ width: '100%', mt:"32px" }}>
                        {/* Profile Information Section */}
                        <Stack>
                            <Box>
                                <Typography variant="h6" align="center" fontWeight="700" color="black">
                                    {t('profile.myProfile')}
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h6" sx={styles.profileTitle}>
                                            {t('profile.firstname')}
                                        </Typography>
                                        {isEditing ? (
                                            <Button onClick={handleSave} sx={styles.editButton} color="primary">
                                                {t('buttons.save')}
                                            </Button>
                                        ) : (
                                            <IconButton onClick={handleEdit}>
                                                <img src={icons.EditIcon} alt="edit" style={styles.editIcon} />
                                            </IconButton>
                                        )}
                                    </Box>
                                    {isEditing ? (
                                        <>
                                            <TextFieldInput
                                                fullWidth
                                                name="name"
                                                value={editedData.name}
                                                onChange={handleChange}
                                                label={t('profile.firstname')}
                                                sx={{ mt: 1 }}
                                            />
                                            <TextFieldInput
                                                fullWidth
                                                name="secondname"
                                                value={editedData.secondname}
                                                onChange={handleChange}
                                                label={t('profile.secondname')}
                                                sx={{ mt: 1 }}
                                            />
                                            <TextFieldInput
                                                fullWidth
                                                name="thirdname"
                                                value={editedData.thirdname}
                                                onChange={handleChange}
                                                label={t('profile.thirdname')}
                                                sx={{ mt: 1 }}
                                            />
                                            <TextFieldInput
                                                fullWidth
                                                name="nickname"
                                                value={editedData.nickname}
                                                onChange={handleChange}
                                                label={t('profile.nickname')}
                                                sx={{ mt: 1 }}
                                            />
                                        </>
                                    ) : (
                                        <Typography sx={styles.profileText}>{getFullName()}</Typography>
                                    )}
                                    <Divider sx={styles.divider} />
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="h6" sx={styles.profileTitle}>
                                        {t('profile.email')}
                                    </Typography>
                                    {isEditing ? (
                                        <TextFieldInput
                                            fullWidth
                                            name="email"
                                            value={editedData.email}
                                            onChange={handleChange}
                                            sx={{ mt: 1 }}
                                        />
                                    ) : (
                                        <Typography sx={styles.profileText}>{user?.email}</Typography>
                                    )}
                                    <Divider sx={styles.divider} />
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="h6" sx={styles.profileTitle}>
                                        {t('profile.phone')}
                                    </Typography>
                                    <Typography sx={styles.profileText}>
                                        {user?.phone_number} {user?.country_code}
                                    </Typography>
                                    <Divider sx={styles.divider} />
                                </Box>
                            </Box>
                        </Stack>

                        {/* Profile Settings Section */}
                        <Stack sx={{ mt: 2 }}>
                            <Box>
                                <Button onClick={handleChangePhoneNumber} sx={styles.buttonMobile}>
                                    {t('profile.changeNumber')}
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={handleChangePassword} sx={{ ...styles.buttonMobile, mt: 2 }}>
                                    {t('profile.changePassword')}
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>
                )}
            </Container>
        </>
    );
};

export default Profile;