import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { withTranslation } from "react-i18next";

const CopyRight = ({ t }) => {
  const { secondaryColor, white } = useThemePalette();
  return (
    <>
      <Stack alignItems="center" bgcolor="#242424" pt="17px" pb="11px">
        <Typography color={white} fontSize="14px">
          {t("home.footer.copy right")}
        </Typography>
      </Stack>
    </>
  );
};

export default withTranslation()(CopyRight);
