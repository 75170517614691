import { ContactUsLoading, GetContactUsSuccess } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetContactUsRequest = () => {
  return async (dispatch) => {

    const requestData = {
      isLoading: ContactUsLoading,
      successFunction: GetContactUsSuccess,
      loadingType: "list contacts",
      url: "/contacts",
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

