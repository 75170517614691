import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
  FormHelperText,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useThemePalette } from "common/hooks/theme_palette";
import PhoneInput from "components/Atoms/Input/PhoneInput/PhoneInput";
import en from "react-phone-number-input/locale/en.json";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signupRequest } from "services/modules/auth";
import TermsModal from "components/Atoms/SharedComponents/Modals/TermsModal";
import {
  ToggleOTPModal,
  TogglePrivacyModal,
  ToggleTermsModal,
} from "services/modules/modals/Actions";
import OTPModal from "components/Atoms/SharedComponents/Modals/OTPModal";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import setAuthToken from "services/modules/utils/handel_api";
import { useValidationSchemas } from "common/utils/schemas";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import PrivacyModal from "components/Atoms/SharedComponents/Modals/PrivacyModal";

const SignupForm = ({ t }) => {
  const { blackTwo } = useThemePalette();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const load = useSelector((state) => state.auth.load);
  const { signupSchema } = useValidationSchemas();
  const isWeb = useMediaQuery("(min-width: 768px)");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const openTermsModal = () => {
    dispatch(
      ToggleTermsModal({
        isOpen: true,
        header: t("signup.termsTitle"),
      })
    );
  };

  const openPrivacyModal = () => {
    dispatch(
      TogglePrivacyModal({
        isOpen: true,
        header: t("signup.privacyTitle"),
      })
    );
  };

  const onSubmit = (values) => {
  
    const obj = {
      user: {
        username: values.username,
        secondname: values.secondname,
        thirdname: values.thirdname,
        country_code: `+${values.country_code}`,
        phone_number: values?.phone_number,
        email: values.email,
        password: values.password,
      },
      device: "android",
    };
  
  
    const action = (data) => {
      setAuthToken(data.data.token);
      dispatch(
        ToggleOTPModal({
          isOpen: true,
          subHeader: t("otp.subHeader2"),
          btnTitle: t("buttons.create-account"),
          verifyUrl: "/auth/verifications/otp/verify",
        })
      );
    };
  
    dispatch(signupRequest({ body: obj, action }));
  };
  

  const initialValues = {
    username: "",
    secondname: "",
    thirdname: "",
    nickname: "",
    phone_number: "",
    country_code: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: signupSchema,
  });
  return (
    <>
      <OTPModal
        t={t}
        phoneNumber={formik.values.phone_number}
        countryCode={`+${formik.values.country_code}`}
        sendUrl="/auth/verifications/otp/send"
        path="/signup"
      />
      <TermsModal t={t} />
      <PrivacyModal t={t} />

      <Stack sx={{ mt: {xs: 60 , sm:50 }, gap: "10px", width: "100%" }}>
        {isWeb && (
          <Stack sx={{ gap: "10px" }}>
        <Typography fontSize={{ xs: "18px", sm: "48px" }} fontWeight="700">
          {t("signup.header")}
        </Typography>
            <Typography fontSize={{ xs: "14px", sm: "26px" }} fontWeight="500">
              {t("signup.subHeader1")}
            </Typography>
            <Typography fontSize={{ xs: "14px", sm: "26px" }} fontWeight="500">
              {t("signup.subHeader2")}
            </Typography>
          </Stack>
        )}
          {!isWeb && (
            <Typography alignItems="center" fontSize="18px" fontWeight="700">
              {t("signup.header")}
            </Typography>
          )}
        <Stack component="form" onSubmit={formik.handleSubmit} sx={{ width: "100%" }}>
          <Stack  direction={isWeb ? "row" : "column"} gap="24px" sx={{ pb: "16px" }}>
            <Stack sx={{  width: isWeb ? "50%" : "100%"}}>
              <Typography
                sx={{ pb: "16px" }}
                color={blackTwo}
                fontWeight="500"
                fontSize={{ xs: "14px", sm: "20px" }}
              >
                {t("signup.firstName")}
              </Typography>
              <TextFieldInput
                id="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                placeholder={t("signup.firstName")}
              />
              {formik.touched.username && formik.errors.username && (
                <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                  {formik.errors.username}
                </FormHelperText>
              )}
            </Stack>
            <Stack sx={{  width: isWeb ? "50%" : "100%"}}>
              <Typography
                sx={{ pb: "16px" }}
                color={blackTwo}
                fontWeight="500"
                fontSize={{ xs: "14px", sm: "20px" }}
              >
                {t("signup.secondName")}
              </Typography>
              <TextFieldInput
                id="secondname"
                name="secondname"
                value={formik.values.secondname}
                onChange={formik.handleChange}
                error={formik.touched.secondname && Boolean(formik.errors.secondname)}
                placeholder={t("signup.secondName")}
              />
              {formik.touched.secondname && formik.errors.secondname && (
                <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                  {formik.errors.secondname}
                </FormHelperText>
              )}
            </Stack>
          </Stack>
          <Stack  direction={isWeb ? "row" : "column"} gap="24px" sx={{ pb: "16px" }}>
            <Stack sx={{  width: isWeb ? "50%" : "100%"}}>
              <Typography
                sx={{ pb: "16px" }}
                color={blackTwo}
                fontWeight="500"
                fontSize={{ xs: "14px", sm: "20px" }}
              >
                {t("signup.thirdName")}
              </Typography>
              <TextFieldInput
                id="thirdname"
                name="thirdname"
                value={formik.values.thirdname}
                onChange={formik.handleChange}
                error={formik.touched.thirdname && Boolean(formik.errors.thirdname)}
                placeholder={t("signup.thirdName")}
              />
              {formik.touched.thirdname && formik.errors.thirdname && (
                <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                  {formik.errors.thirdname}
                </FormHelperText>
              )}
            </Stack>
            <Stack sx={{  width: isWeb ? "50%" : "100%"}}>
              <Typography
                sx={{ pb: "16px" }}
                color={blackTwo}
                fontWeight="500"
                fontSize={{ xs: "14px", sm: "20px" }}
              >
                {t("signup.nickname")}
              </Typography>
              <TextFieldInput
                id="nickname"
                name="nickname"
                value={formik.values.nickname}
                onChange={formik.handleChange}
                error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                placeholder={t("signup.nickname")}
              />
              {formik.touched.nickname && formik.errors.nickname && (
                <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                  {formik.errors.nickname}
                </FormHelperText>
              )}
            </Stack>
          </Stack>
          <Stack sx={{ pb: "16px" }}>
            <Typography
              sx={{ pb: "16px" }}
              color={blackTwo}
              fontWeight="500"
              fontSize={{ xs: "14px", sm: "20px" }}
            >
              {t("signup.phone")}
            </Typography>
            <PhoneInput
              id="phone_number"
              name="phone_number"
              value={formik.values.country_code}
              inputValue={formik.values.phone_number}
              onChange={(value) => formik.setFieldValue("country_code", value)}
              setPhone={(e) => formik.setFieldValue("phone_number", e?.target?.value)}
              error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
              labels={en}
              defaultCountry="EG"
              countries={["EG"]}
            />
            {formik.touched.phone_number && formik.errors.phone_number && (
              <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                {formik.errors.phone_number}
              </FormHelperText>
            )}
          </Stack>
          <Stack sx={{ pb: "16px" }}>
            <Typography
              sx={{ pb: "16px" }}
              color={blackTwo}
              fontWeight="500"
              fontSize={{ xs: "14px", sm: "20px" }}
            >
              {t("signup.email")}
            </Typography>
            <TextFieldInput
              id="email"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              placeholder={t("signup.email")}
            />
            {formik.touched.email && formik.errors.email && (
              <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                {formik.errors.email}
              </FormHelperText>
            )}
          </Stack>
          <Stack sx={{ pb: "16px" }}>
            <Typography
              sx={{ pb: "16px" }}
              color={blackTwo}
              fontWeight="500"
              fontSize={{ xs: "14px", sm: "20px" }}
            >
              {t("signup.password")}
            </Typography>
            <TextFieldInput
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              placeholder={t("signup.password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.password && formik.errors.password && (
              <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                {formik.errors.password}
              </FormHelperText>
            )}
          </Stack>
          <Stack sx={{ pb: "16px" }}>
            <Typography
              sx={{ pb: "16px" }}
              color={blackTwo}
              fontWeight="500"
              fontSize={{ xs: "14px", sm: "20px" }}
            >
              {t("signup.confirm")}
            </Typography>
            <TextFieldInput
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              placeholder={t("signup.confirm")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                {formik.errors.confirmPassword}
              </FormHelperText>
            )}
          </Stack>
          <Stack sx={{ pb: "16px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="terms"
                  checked={formik.values.terms}
                  onChange={formik.handleChange}
                  color="primary"
                  error={formik.touched.terms && Boolean(formik.errors.terms)}
                />
              }
              label={
                <Typography component="span">
                  {t("signup.agree")}
                  <Typography
                    component="span"
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginRight: "3px",
                    }}
                    onClick={openPrivacyModal}
                  >
                    {t("signup.privacy")}
                  </Typography>
                  {t("signup.and")}
                  <Typography
                    component="span"
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginRight: "3px",
                    }}
                    onClick={openTermsModal}
                  >
                    {t("signup.terms")}
                  </Typography>
                </Typography>
              }
            />
            {formik.touched.terms && formik.errors.terms && (
              <FormHelperText sx={{ color: "#ef5350", marginTop: "5px", marginLeft: "5px" }}>
                {formik.errors.terms}
              </FormHelperText>
            )}
          </Stack>
          <Stack>
            <LoadingBtn
              type="submit"
              variant="contained"
              sx={{ color: "secondary.main", borderRadius: "10px" }}
              isLoading={load?.loading && load?.type === "signup"}
            >
              {t("buttons.next")}
            </LoadingBtn>
          </Stack>

          <Stack direction="row" justifyContent="center" alignItems="center" mt={3}>
            <Typography fontSize={{ xs: "14px", sm: "21px" }} fontWeight="400">
              {t("signup.user")}
            </Typography>
            <Button
              sx={{ fontSize: { xs: "14px", sm: "21px" }, fontWeight: "700", ml: "5px" }}
              onClick={() => navigate("/login")}
            >
              {t("buttons.login")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default withTranslation()(SignupForm);
