import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Box,
  Stack,
  Typography,
  MenuItem,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import PhoneInput from "components/Atoms/Input/PhoneInput/PhoneInput";
import { useFormik } from "formik";
import { useValidationSchemas } from "common/utils/schemas";
import en from "react-phone-number-input/locale/en.json";
import { ToggleLegalServiceModal, ToggleSuccessModal } from "services/modules/modals/Actions";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import { GetSpecialtiesRequest } from "services/modules/specialities";
import { withTranslation } from "react-i18next";
import { SubmitServiceRequest } from "services/modules/legalServices";
import SuccessModal from "./SucessModal";

const LegalServiceModal = ({ t }) => {
  const dispatch = useDispatch();
  const { isOpen, id } = useSelector((state) => state.modals.legalService);
  const { specialities } = useSelector((state) => state.specialities);
  const load = useSelector((state) => state.auth.load);
  const { legalServiceSchema } = useValidationSchemas();

  const onClose = () => {
    dispatch(ToggleLegalServiceModal({ isOpen: false }));
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(GetSpecialtiesRequest());
    }
  }, [isOpen, dispatch]);

  // const fetchSpecialties = () => {
  //   dispatch(GetSpecialtiesRequest());
  // };

  const initialValues = {
    message: "",
    serviceType: "",
    date: "",
    phone_number: "",
  };

  const handleSubmit = (values) => {
    const selectedSpeciality = specialities.find(
      (speciality) => speciality.id === values.serviceType
    );

    const requestData = {
      body: {
        service_request: {
          message: values.message,
          service_id: id,
          speciality_id: selectedSpeciality ? selectedSpeciality.id : null,
          date: values.date,
          email: "example@gmail.com",
          phone_number: values.phone_number,
        },
      },
      action: () => {
        onClose();
        dispatch(
          ToggleSuccessModal({
            isOpen: true,
            message: t("success.legalService"),
          })
        );
      },
    };
    dispatch(SubmitServiceRequest(requestData));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: legalServiceSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <SuccessModal t={t} />
      <Modal open={isOpen} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            maxWidth: "100%",
            maxHeight: { xs: "100vh", sm: "90vh" },
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: { xs: 2, sm: 4 },
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography fontSize="24px" textAlign="center" fontWeight="700">
            {t("serviceRequest.header")}
          </Typography>
          <Typography fontSize="18px" textAlign="center" fontWeight="400">
            {t("serviceRequest.subHeader")}
          </Typography>

          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              paddingRight: "16px",
            }}
          >
            <Stack component="form" sx={{ gap: "20px" }} onSubmit={formik.handleSubmit}>
              <Stack gap="7px">
                <Typography fontSize="18px" fontWeight="500">
                  {t("serviceRequest.subjectText")}
                </Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={formik.touched.message && Boolean(formik.errors.message)}
                >
                  <TextFieldInput
                    placeholder={t("serviceRequest.subjectText")}
                    multiline
                    rows={4}
                    id="message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={`${formik.values.message.length}/300`}
                    variant="outlined"
                  />
                  {formik.touched.message && formik.errors.message && (
                    <FormHelperText>{formik.errors.message}</FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack gap="7px">
                <Typography fontSize="18px" fontWeight="500">
                  {t("serviceRequest.specialization")}
                </Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={formik.touched.serviceType && Boolean(formik.errors.serviceType)}
                >
                  <TextFieldInput
                    placeholder={t("serviceRequest.specialization")}
                    select
                    id="serviceType"
                    name="serviceType"
                    value={formik.values.serviceType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // onClick={fetchSpecialties} 
                    variant="outlined"
                  >
                    {specialities.map((speciality) => (
                      <MenuItem key={speciality.id} value={speciality.id}>
                        {speciality.name}
                      </MenuItem>
                    ))}
                  </TextFieldInput>
                  {formik.touched.serviceType && formik.errors.serviceType && (
                    <FormHelperText>{formik.errors.serviceType}</FormHelperText>
                  )}
                </FormControl>
              </Stack>
              <Stack gap="7px">
                <Typography fontSize="18px" fontWeight="500">
                  {t("serviceRequest.appointmentPlaceholder")}
                </Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={formik.touched.date && Boolean(formik.errors.date)}
                >
                  <TextFieldInput
                    placeholder={t("serviceRequest.appointmentPlaceholder")}
                    id="date"
                    name="date"
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                  />
                  {formik.touched.date && formik.errors.date && (
                    <FormHelperText>{formik.errors.date}</FormHelperText>
                  )}
                </FormControl>
              </Stack>
              <Stack gap="7px">
                {" "}
                <Typography fontSize="18px" fontWeight="500">
                  {t("serviceRequest.phoneInput")}
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <PhoneInput
                    id="phone_number"
                    name="phone_number"
                    className="normal-textField user-phone"
                    selectId="search-select"
                    inputValue={formik.values.phone_number}
                    inputType="number"
                    setPhone={(e) =>
                      formik.setValues({
                        ...formik.values,
                        phone_number: e.target.value,
                      })
                    }
                    placeholder={t("login.phone")}
                    labels={en}
                    value={formik.values.country_code}
                    onChange={(value) =>
                      formik.setValues({
                        ...formik.values,
                        country_code: value,
                      })
                    }
                    codePlus={true}
                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                  />
                </Stack>
              </Stack>

              <LoadingBtn
                type="submit"
                variant="contained"
                sx={{
                  mt: 4,
                  color: "secondary.main",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "14px",
                }}
                isLoading={load?.loading && load?.type === "legalService"}
                // disabled={load?.loading}
              >
                {t("buttons.send")}
              </LoadingBtn>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default withTranslation()(LegalServiceModal);
