import React from "react";
import { Box, Card, CardContent, Typography, Button, Stack, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToggleGuestModeModal } from "services/modules/modals/Actions";
import { styles } from "./Styles";

const BookCard = ({ book }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isWeb = useMediaQuery("(min-width: 768px)");
  const token = useSelector((state) => state.auth.userData.token);

  const openGuestModeModal = () => {
    dispatch(
      ToggleGuestModeModal({
        isOpen: true,
        header: t("home.books.header"),
        subHeader: t("home.books.subHeader"),
      })
    );
  };

  const handleClick = () => {
    if (!token) {
      openGuestModeModal();
    } else if (book.link) {
      window.location.href = book.link;
    }
  };

  const imageUrl = book.images && book.images[0] ? book.images[0].image_url : defaultImage;

  return (
    <Stack sx={styles.container}>
      <Card sx={styles.card}>
        <Box component="figure" sx={styles.imageContainer}>
          <Box component="img" src={imageUrl} alt={book.name} sx={styles.image} />
        </Box>

        <Stack sx={{ ...styles.cardContent, width: { xs: "100%", sm: "60%" } }}>
          <CardContent sx={styles.cardContent}>
            <Typography sx={styles.title}>{book?.name}</Typography>
            <Typography sx={styles.description}>{book?.description}</Typography>
            <Typography sx={styles.releaseDate}>
              {t("home.books.releaseDate")} {book?.date}
            </Typography>
          </CardContent>
            <Stack>
              {isWeb && (
                <Button variant="contained" onClick={handleClick} sx={styles.button}>
                  {t("buttons.downloadBook")}
                </Button>
              )}
            </Stack>
        </Stack>
      </Card>
      {!isWeb && (
        <Button variant="contained" onClick={handleClick} sx={styles.buttonMobile}>
          {t("buttons.downloadBook")}
        </Button>
      )}
    </Stack>
  );
};

export default BookCard;
