import React from "react";
import { withTranslation } from "react-i18next";
import Header from "components/Atoms/SharedComponents/Header";
import LegalServices from "components/Organisms/home/LegalServices";
import OurBooks from "components/Organisms/home/OurBooks";
import Partners from "components/Organisms/home/Partners";
import AboutUs from "components/Organisms/home/AboutUs";
import { Stack } from "@mui/material";
import WhatsappIcon from "components/Atoms/SharedComponents/WhatsappIcon";

const HomeTemplate = ({ t }) => {
  return (
    <>
      <Header
        title={t("header.home.title")}
        subtitle={t("header.home.subtitle")}
        buttonText={t("home.pages.legalServices")}
      />
      <Stack gap={{ xs: 0, sm: "50px" }}>
        <AboutUs />
        <LegalServices />
        <OurBooks />
        <Partners />
        <WhatsappIcon/>
      </Stack>
    </>
  );
};

export default withTranslation()(HomeTemplate);