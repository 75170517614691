import Types from "./Types";

const INIT_STATE = {
  important_links: [],
};

export function linksReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.LINKS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case Types.GET_LINKS_SUCCESS:
      return {
        ...state,
        important_links: payload?.data?.important_links, 
      };
    default:
      return state;
  }
}
