import Types from "./Types";
export const fileUploadSuccess = (payload) => ({
  type: Types.FILE_UPLOAD_SUCCESS,
  payload,
});
export const fileUploadFail = (payload) => ({
  type: Types.FILE_UPLOAD_FAIL,
  payload,
});
export const fileUploadLoading = (payload) => ({
  type: Types.FILE_UPLOAD_LOADING,
  payload,
});
export const fileUploadOnServerLoading = (payload) => ({
  type: Types.FILE_UPLOAD_ON_SERVER_LOADING,
  payload,
});
export const getActiveIndex = (payload) => ({
  type: Types.GET_ACTIVE_INDEX,
  payload,
});
export const fileEmptySuccess = () => ({
  type: Types.FILE_EMPTY,
});