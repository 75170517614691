import Header from "components/Atoms/SharedComponents/Header";
import ContactUs from "components/Organisms/contactus/ContactUs";
import React from "react";
import { withTranslation } from "react-i18next";

const ContactUsTemplate = ({ t }) => {
  return (
    <>
    <Header 
     title={t("header.contactUs.title")}
     subtitle={t("header.contactUs.subtitle")}
    />
    <ContactUs />
    </> 
  );
}
export default withTranslation()(ContactUsTemplate);
