

import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const createAppTheme = (language) => {
  const basicTheme = createTheme({
    button: {
      fontFamily: ["Tajawal,sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#1A1A1A",
      },
      secondary: {
        main: "#D4B773",
      },
      info: {
        main: "#FFFFFF",
      },
    },
    theme_palette: {
      primaryColor: "#1A1A1A",
      secondaryColor: "#D4B773",
      white: "#fff",
      gray: "#616161",
      lightGray: "#D8D8D8",
      lightGray2: "#F5F4F4",
      lightGray3: "#838383",
      red: "#CB2127",
      redTwo: "#BF0022",
      redThree: "#CA2228",
      blackTwo: "#333333",
      whiteTwo: "#FAFAFA",
      transparentGray: "rgba(60, 69, 80, 0.679688)",
      subText: "#363636",
      grayThree: "#7C7C7C",
      darkBlue: "#262A35",
      green: "#37B44E",
      blackThree: "#1C1C1C",
      lightBlue: "#40AADF",
      whiteThree: "#E8E8E8",
      whiteFour: "#FBFBFB",
      lightRed: "#951A1D",
      borderColor: "#CECECE",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1445,
        xxl: 1536,
      },
    },
    overrides: {
      MuiPaper: {
        root: {},
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlined: {
            "&:hover": {
              boxShadow: "none !important",
            },
          },
          contained: {},
          root: {
            "&.Mui-disabled": {},
            "&:hover": {},
            // borderRadius: '5px',
            // fontSize: '16px',
            // fontWeight: '600',
            textTransform: "none",
            minWidth: "auto",
            boxShadow: "none !important",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:hover": {},
          },
        },
      },
    },
  });

  const rtlTheme = createTheme({
    ...basicTheme,
    typography: {
      fontFamily: ["Tajawal,sans-serif"].join(","),
      h1: {
        fontFamily: ["Tajawal,sans-serif"].join(","),
        fontSize: "40px",
        fontWeight: "700",
      },
    },
  });

  const ltrTheme = createTheme({
    ...basicTheme,
    typography: {
      fontFamily: ["Poppins,sans-serif"].join(","),
      h1: {
        fontFamily: ["Poppins,sans-serif"].join(","),
        fontSize: "10px",
        fontWeight: "500",
      },
    },
  });

  const theme = language == "ar" ? rtlTheme : ltrTheme;
  return theme;
};

const AppThemeProvider = ({ children }) => {
  const { lang } = useSelector((state) => state.language);
  const theme = createAppTheme(lang);

  document.dir = lang === "ar" ? "rtl" : "ltr";

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppThemeProvider;
