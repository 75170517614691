const ModalType = {

  VERIFICATIOM_MODAL: 'VERIFICATIOM_MODAL',
  FORGET_PASSWORD_MODAL: 'FORGET_PASSWORD_MODAL',
  TOGGLE_PHONE_INPUT_MODAL: "TOGGLE_PHONE_INPUT_MODAL",
  TOGGLE_OTP_MODAL: "TOGGLE_OTP_MODAL",
  TOGGLE_RESET_PASSWORD_MODAL: "TOGGLE_RESET_PASSWORD_MODAL",
  TOGGLE_SUCCESS_MODAL: "TOGGLE_SUCCESS_MODAL",
  TOGGLE_LOGOUT_MODAL: "TOGGLE_LOGOUT_MODAL",
  TOGGLE_TERMS_MODAL: "TOGGLE_TERMS_MODAL",
  TOGGLE_PRIVACY_MODAL: "TOGGLE_PRIVACY_MODAL",
  TOGGLE_GUEST_MODE_MODAL: "TOGGLE_GUEST_MODE_MODAL",
  TOGGLE_LEGAL_SERVICE_MODAL: "TOGGLE_LEGAL_SERVICE_MODAL",
  TOGGLE_CHANGE_PASSWORD_MODAL: "TOGGLE_CHANGE_PASSWORD_MODAL",


};
export default ModalType;