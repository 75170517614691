import axios from "axios";

export const initAxios = () => {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use((response) => response);
};

const setAuthToken = (token) => {
  // axios.defaults.headers.common["platform"] = `web`;
  axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("i18nextLng");

  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
