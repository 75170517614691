import Types from "./Types";


export const AboutUsLoading = (payload) => ({
  type: Types.ABOUT_US_LOADING,
  payload,
});


export const GetAboutUsSuccess = (payload) => ({
  type: Types.GET_ABOUT_US_SUCCESS,
  payload
});

export const GetFullAboutUsSuccess = (payload) => ({
  type: Types.GET_FULL_ABOUT_US_SUCCESS,
  payload
});
