import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetBooksRequest } from "services/modules/books";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import BookCard from "components/Molecules/Home/BookCard";
import GuestModeModal from "components/Atoms/SharedComponents/Modals/GuestModeModal";
import ShowComponentWhen from "components/Atoms/SharedComponents/ShowComponentWhen";

const Books = () => {
  const dispatch = useDispatch();
  const { books } = useSelector((state) => state.books);
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    dispatch(GetBooksRequest());
  }, [dispatch]);

  const renderBookCards = () => (
    <Grid container spacing={{ xs: 0, md: 6 }} rowGap={{ xs: "35px", md: 0 }}>
      {books.map((book) => (
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          key={book.id}
          sx={{ justifyContent: "center", alignItems: "stretch" }}
        >
          <BookCard book={book} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <GuestModeModal t={t} />
      <Container sx={{ maxWidth: { xl: "xl", md: "lg" }, my: "70px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "20px",
          }}
        >
          <ShowComponentWhen
            when={!isSmallScreen}
            show={
              <Typography fontSize="22px" fontWeight="500" mt="16px" gutterBottom>
                {t("header.books.title")}
              </Typography>
            }
          />
        </Box>
        <ShowComponentWhen
          when={!isSmallScreen}
          show={
            <Box sx={{ border: "1px solid #EFEFEF", borderRadius: "14px", padding: "12px" }}>
              {renderBookCards()}
            </Box>
          }
          elseComponent={renderBookCards()}
        />
      </Container>
    </>
  );
};

export default Books;
