import React from "react";
import { Card, CardMedia, Typography, Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToggleGuestModeModal, ToggleLegalServiceModal } from "services/modules/modals/Actions";

const ServiceCard = ({ service }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.userData.token);

  const openLegalServiceModal = () => {
    dispatch(
      ToggleLegalServiceModal({
        isOpen: true,
        id: service?.id,
      })
    );
  };
  const openGuestModeModal = () => {
    dispatch(
      ToggleGuestModeModal({
        isOpen: true,
        header: t("home.legal services.header"),
        subHeader: t("home.legal services.subHeader"),
      })
    );
  };
  const handleClick = () => {
    if (token) {
      openLegalServiceModal();
    } else {
      openGuestModeModal();
    }
  };
  return (
    <Stack
      sx={{ width: "100%", textAlign: "center", gap: "24px", cursor: "pointer" }}
      onClick={handleClick}
    >
      <Card
        sx={{
          width: "100%",
          height: 170,
          transition: "transform 0.3s",
          "&:hover": {
            transform: "translateY(-5px)",
          },
        }}
      >
        <CardMedia
          component="img"
          image={service.images[0].image_url}
          alt={service.name}
          sx={{
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Card>
      <Typography
        variant="h6"
        sx={{
          // mt: 2,
          justifyContent:"center",
          fontSize: { xs: "18px", lg: "22px" },
          maxWidth: 280,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {service?.name}
      </Typography>
    </Stack>
  );
};

export default ServiceCard;
