import React, { useEffect } from "react";
import { Box, Button, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import { images, icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { TogglePrivacyModal, ToggleTermsModal } from "services/modules/modals/Actions";
import { useDispatch, useSelector } from "react-redux";
import { GetContactUsRequest } from "services/modules/contactUs";
import TermsModal from "components/Atoms/SharedComponents/Modals/TermsModal";
import PrivacyModal from "components/Atoms/SharedComponents/Modals/PrivacyModal";

const Footer = ({ t }) => {
  const { secondaryColor } = useThemePalette();
  const dispatch = useDispatch();
  const isWeb = useMediaQuery("(min-width: 768px)");
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(GetContactUsRequest());
  }, [dispatch]);

  const { contacts } = useSelector((state) => state.contactUs);

  const socialMediaContacts = contacts.filter((contact) =>
    ["twitter", "Instagram", "Linkedin", "Whatsapp"].includes(contact.name)
  );

  const otherContacts = contacts
    .filter((contact) => !["twitter", "Instagram", "Linkedin", "Whatsapp"].includes(contact.name))
    .sort((a, b) => {
      const order = ["Address", "Email", "Phone"];
      return order.indexOf(a.name) - order.indexOf(b.name);
    });

  const openTermsModal = () => {
    dispatch(
      ToggleTermsModal({
        isOpen: true,
        header: t("signup.termsTitle"),
      })
    );
  };

  const openPrivacyModal = () => {
    dispatch(
      TogglePrivacyModal({
        isOpen: true,
        header: t("signup.privacyTitle"),
      })
    );
  };

  const pages = [
    { title: t("home.pages.home"), path: "/" },
    { title: t("home.pages.legalServices"), path: "/legal-services" },
    { title: t("home.pages.books"), path: "/our-books" },
    { title: t("home.pages.links"), path: "/important-links" },
    { title: t("home.header.button"), path: "/about-us" },
    { title: t("home.footer.terms"), path: "", action: openTermsModal },
    { title: t("home.footer.privacy"), path: "", action: openPrivacyModal },
  ];

  // Function to get the icons based on language
  const getIcon = (iconName) => {
    const currentLanguage = i18n.language;
    const iconMap = {
      Tabby: currentLanguage === "ar" ? icons.TabbyAr : icons.TabbyEn,
      Tamara: currentLanguage === "ar" ? icons.TamaraAr : icons.TamaraEn,
    };
    return iconMap[iconName];
  };

  return (
    <>
      <TermsModal t={t} />
      <PrivacyModal t={t} />
      <Box
        sx={{
          backgroundColor: "#000",
          color: "#fff",
          padding: { xs: "10px", lg: "50px" },
        }}
      >
        <Container
          sx={{
            maxWidth: { xl: "xl", md: "lg" },
          }}
        >
          <Stack
            direction={isWeb ? "row" : "column"}
            justifyContent="space-between"
            alignItems="start"
            spacing={2}
          >
            {/* Logo and Description */}
            <Stack width={{ xs: "100%", md: "33%" }} justifyContent="center" alignItems="center">
              <Box
                component="img"
                src={images.Logo}
                sx={{ width: { xs: "150px", md: "269px" } }}
                alt="logo"
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: "12px", sm: "20px" },
                  }}
                >
                  {t("home.footer.description")}
                </Typography>
              </Box>
              {isWeb && (
                <Stack direction="row" sx={{ width: "100%", pt: "20px" }} gap={2}>
                  {socialMediaContacts.map((contact) => (
                    <Button
                      key={contact.id}
                      href={contact.value}
                      target="_blank"
                      sx={{
                        minWidth: 0,
                        padding: 0,
                        color: "#fff",
                      }}
                    >
                      <Box
                        component="img"
                        src={contact.images}
                        alt={contact.name}
                        sx={{ width: 24, height: 24 }}
                      />
                    </Button>
                  ))}
                </Stack>
              )}
            </Stack>

            {/* Pages and Contact Information */}
            <Stack direction="row" width={{ xs: "100%", md: "55%" }} gap={isWeb ? "150px" : "30px"}>
              {/* Pages */}
              <Stack
                width={{ xs: "100%", md: "auto" }}
                alignItems="flex-start"
                justifyContent="center"
                gap={isWeb ? "15px" : "25px"}
              >
                {pages.map((page, index) => (
                  <Button
                    key={index}
                    component={Link}
                    to={page.path}
                    variant="text"
                    color="primary"
                    sx={{
                      textTransform: "none",
                      color: "white",
                      fontSize: { xs: "12px", sm: "20px" },
                      p: 0,
                    }}
                    onClick={page.action}
                  >
                    {page.title}
                  </Button>
                ))}
              </Stack>
              {/* Contact Information */}
              <Stack width={{ xs: "100%", sm: "auto" }} gap={isWeb ? "30px" : "16px"}>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "20px" },
                    fontWeight: "500",
                    color: "white",
                  }}
                >
                  {t("home.footer.title")}
                </Typography>
                <Stack gap={{ xs: "10px", sm: "38px" }}>
                  {otherContacts.map((contact) => (
                    <Stack key={contact.id} direction="row" alignItems="center">
                      <Box
                        component="img"
                        src={contact.images}
                        alt={contact.name}
                        sx={{
                          width: 16,
                          height: 18,
                        }}
                      />
                      <Typography
                        sx={{
                          px: 2,
                          fontSize: { xs: "12px", sm: "20px" },
                          color: "#fff",
                        }}
                      >
                        {contact.value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>

                {/* Payment Channels */}
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", sm: "20px" },
                      fontWeight: "500",
                      color: "white",
                    }}
                  >
                    {t("home.footer.payOnline")}
                  </Typography>
                  <Stack direction="row" gap="10px" pt="10px">
                    <Button href="https://tabby.ai/ar-SA/pay-later" target="_blank">
                      <Box
                        component="img"
                        src={getIcon("Tabby")}
                        alt="Tabby"
                        sx={{ width: 80, height: 30 }}
                      />
                    </Button>
                    <Button href="https://tamara.co/ar-SA" target="_blank">
                      <Box
                        component="img"
                        src={getIcon("Tamara")}
                        alt="Tamara"
                        sx={{ width: 80, height: 30 }}
                      />
                    </Button>
                  </Stack>
                </Box>

                {!isWeb && (
                  <Stack width="100%" direction="row" gap="20px">
                    {socialMediaContacts.map((contact) => (
                      <Button
                        key={contact.id}
                        href={contact.value}
                        target="_blank"
                        sx={{
                          minWidth: 0,
                          padding: 0,
                          color: "#fff",
                        }}
                      >
                        <Box
                          component="img"
                          src={contact.images}
                          alt={contact.name}
                          sx={{ width: 18, height: 18 }}
                        />
                      </Button>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default withTranslation()(Footer);