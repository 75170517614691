import { GetPartnersSuccess, PartnersLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetPartnersRequest = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const requestData = {
      isLoading: PartnersLoading,
      successFunction: GetPartnersSuccess,
      loadingType: "list partners",
      url: "/partners",
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
