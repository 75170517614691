export const styles = {
  container: {
    flexDirection: { xs: "column", sm: "row" },
    width: "100%",
    height: { xs: "100%", sm: "300px" },
    alignSelf: "stretch",
    rowGap: "24px",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    bgcolor: "background.paper",
    boxShadow: "none",
    padding: 0,
    gap: { xs: "10px", sm: "20px" },
  },
  imageContainer: {
    width: "250px",
    fontSize: 0,
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EFEFEF",
    borderRadius: "8px",
  },
  image: {
    maxWidth: "100%",
    objectFit: "cover",
    maxHeight: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // height: "100%",
    padding: 0,
    paddingBottom: "0 !important",
    gap: { xs: "20px", sm: "24px" },
  },
  title: {
    fontWeight: "700",
    minHeight: "32px",
    fontSize: { xs: "18px", sm: "24px" },
  },
  description: {
    fontWeight: "400",
    color: "textSecondary",
    fontSize: { xs: "14px", sm: "18px" },
    overflow: "hidden",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflowWrap: "break-word",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    lineHeight: "1.6em",
  },
  releaseDate: {
    fontSize: "16px",
    fontWeight: "600",
    minHeight: "24px",
  },
  button: {
    fontWeight: 500,
    fontSize: "16px",
    paddingY: "10px",
    width: "80%",
    color: "secondary.main",
  },
  buttonMobile: {
    fontWeight: 500,
    fontSize: "16px",
    paddingY: "10px",
    width: "100%",
    color: "secondary.main",
    "&:hover": {
      bgcolor: "primary.dark",
    },
  },
};
