import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetTeamsRequest } from "services/modules/teamWork";
import { styles } from "./Styles";

const TeamWork = () => {
  const dispatch = useDispatch();
  const { teams } = useSelector((state) => state.teamWork);
  const { t, i18n } = useTranslation();
  const isWeb = useMediaQuery("(min-width: 768px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    dispatch(GetTeamsRequest());
  }, [dispatch]);

  const sortMembers = (members) => {
    return members.sort((a, b) => {
      if (
        (i18n.language === "ar" && a.name === "صاحب السمو الأمير أحمد بندر  بن أحمد السديري") ||
        (i18n.language === "en" && a.name === "Prince Ahmed Bandar bin Ahmed Al-Sudairi")
      )
        return -1;
      if (
        (i18n.language === "ar" && b.name === "صاحب السمو الأمير أحمد بندر  بن أحمد السديري") ||
        (i18n.language === "en" && b.name === "Prince Ahmed Bandar bin Ahmed Al-Sudairi")
      )
        return 1;
      return 0;
    });
  };

  return (
    <Container sx={styles.container}>
      {!isWeb && (
        <Typography fontSize="22px" fontWeight="500" mb={2} mt={10} gutterBottom>
          {t("header.teamWork.title")}
        </Typography>
      )}
      {teams.map((team, teamIndex) => {
        const sortedMembers = sortMembers(team.members);

        return (
          <Box key={team.id} sx={{ mb: teamIndex < teams.length - 1 ? 8 : 0 }}>
            <Typography sx={styles.title}>
              {team.name === "فريق1" ? t("team.header") : t("team.subHeader")}
            </Typography>
            <Grid container spacing={4}>
              {sortedMembers.map((member, index) => {
                const isFirstMember = index === 0 && teamIndex === 0;
                const cardStyle = isFirstMember
                  ? {
                      ...styles.memberBox,
                      flexDirection: isSmallScreen ? "column" : "row",
                      alignItems: isSmallScreen ? "center" : "center",
                      justifyContent: isSmallScreen ? "center" : "flex-start",
                    }
                  : styles.memberBox;

                return (
                  <Grid item xs={12} md={isFirstMember ? 12 : 4} key={index}>
                    <Box sx={cardStyle}>
                      {isFirstMember && (
                        <img
                          src={member.image[0].image_url}
                          alt={member.name}
                          style={{
                            borderRadius: "8px",
                            width: isSmallScreen ? "100%" : "400px",
                            height: "auto",
                            marginBottom: isSmallScreen ? "10px" : "0",
                            marginRight: isSmallScreen ? "0" : "20px",
                          }}
                        />
                      )}
                      {!isFirstMember && member.image && (
                        <img
                          src={member.image[0].image_url}
                          alt={member.name}
                          style={{
                            borderRadius: "8px",
                            width: "100%",
                            height: "auto",
                            marginBottom: "10px",
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          textAlign: isFirstMember
                            ? isSmallScreen
                              ? "center"
                              : "right"
                            : "center",
                        }}
                      >
                        <Typography sx={styles.memberTitle}>{member.title}</Typography>
                        <Typography sx={styles.memberName}>{member.name}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );
      })}
    </Container>
  );
};

export default TeamWork;
