import {  GetTeamSuccess, TeamLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetTeamsRequest = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const requestData = {
      isLoading: TeamLoading,
      successFunction: GetTeamSuccess,
      loadingType: "list partners",
      url: "/teams",
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
