import { CareersLoading, CareersRequestSuccess, GetCareersSuccess, GetServicesSuccess, ServiceRequestSuccess, ServicesLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const SubmitCareersRequest = ({...variables}) => {
  const requestData = {
      loadingType: "careers request",
      isLoading: CareersLoading,
      successFunction: CareersRequestSuccess,
      url: "/career/apply",
      // action: variables?.action,
      method: "post",
      data: variables?.body,
    };

    return async (dispatch) => {
      reduxRequest({
        dispatch,
        ...requestData,
      });
  };
};

export const GetCareersRequest = () => {
    // const state = getState();

    const requestData = {
      isLoading: CareersLoading,
      successFunction: GetCareersSuccess,
      loadingType: "list career",
      url: "/career/apply",
      method: "get",
    };

    return async (dispatch)   => {
      reduxRequest({
        dispatch,
        ...requestData,
      });
  };
};