import setAuthToken from "../utils/handel_api";
import Types from "./Types";
const INIT_STATE = {
  userData: {
    email: "",
    name: "",
    phone_number: "",
    country_code: "",
    token: null,
  },
  resetToken: null,
  load: null,
};
export default function authReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.AUTH_SUCCESS: {
      return {
        ...state,
        userData: {
          otp: payload?.otp,
        },
      };
    }
    case Types.OTP_SUCCESS: {
      console.log(payload, "payload");
      return {
        ...state,
        userData: {
          ...state.userData,
          ...payload?.data?.user,
        },
        resetToken: payload?.data?.reset_password_token,
      };
    }
    case Types.AUTH_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.LOGIN_SUCCESS: {
      const { data } = payload;
      setAuthToken(data.token);
      return {
        ...state,
        userData: {
          ...data.user,
          token: data.token,
        },
      };
    }
    case Types.SIGN_UP_SUCCESS: {
      const { data } = payload;
      // setAuthToken(data.token);
      return {
        ...state,
        userData: {
          ...data.user,
          otp: data?.otp,
          // token: data.token,
        },
      };
    }

    case Types.SET_TOKEN_SUCCESS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          token: payload,
        },
      };
    }

    case Types.LOGOUT_SUCCESS: {
      return INIT_STATE;
    }

    case Types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        user: payload?.data?.user,
      };

    case Types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...payload?.data?.user,
        },
      };

    default: {
      return state;
    }
  }
}
