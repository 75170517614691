import {  LinksLoading, GetLinksSuccess } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetLinksRequest = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const requestData = {
      isLoading: LinksLoading,
      successFunction: GetLinksSuccess,
      loadingType: "list important links",
      url: "/links",
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
