import { useState, useEffect, useRef, useCallback } from 'react';

export const useTimer = (onEnd, duration = 70) => {
  const [seconds, setSeconds] = useState(duration);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalRef.current);
      if (onEnd) onEnd();
    }
  }, [seconds, onEnd]);

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const startTimer = useCallback(() => {
    clearInterval(intervalRef.current);
    setSeconds(duration);
    intervalRef.current = setInterval(() => {
      setSeconds(prev => {
        return prev - 1;
      });
    }, 1000);
  }, [duration]);

  const resetTimer = useCallback(() => {
    startTimer();
  }, [startTimer]);

  return { seconds, resetTimer, startTimer };
};
