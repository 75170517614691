import axios from "axios";
import { BaseURL } from "services/modules/utils/constant";
import handleErrors from "services/modules/utils/handel_error";
import { store } from "services/store";

export const reduxRequest = async ({
  dispatch,
  isLoading,
  url,
  method = "post",
  loadingType = "list",
  data: body,
  action,
  successFunction,
}) => {
  dispatch(
    isLoading({
      type: loadingType,
      loading: true,
    })
  );
  try {
    const state = store.getState();
    const { lang } = state.language;
    const { data } = await axios({
      url: url,
      baseURL: BaseURL,
      method: method,
      data: body,
      // headers: {
      //   'Accept-Language': lang,
      // }
    });
    successFunction && dispatch(successFunction(data));
    action && action(data);
  } catch (error) {
    handleErrors(error);
  } finally {
    dispatch(
      isLoading({
        type: "",
        loading: false,
      })
    );
  }
};
