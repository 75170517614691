import { GetServicesSuccess, ServiceRequestSuccess, ServicesLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetServicesRequest = () => {
  return async (dispatch) => {

    const requestData = {
      isLoading: ServicesLoading,
      successFunction: GetServicesSuccess,
      loadingType: "list services",
      url: "/services",
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const SubmitServiceRequest = ({...variables}) => {
  const requestData = {
      loadingType: "service request",
      isLoading: ServicesLoading,
      successFunction: ServiceRequestSuccess,
      url: "/service_request",
      action: variables?.action,
      method: "post",
      data: variables?.body,
    };

    return async (dispatch) => {
      reduxRequest({
        dispatch,
        ...requestData,
      });
  };
};