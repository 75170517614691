import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Modal,
    Box,
    Typography,
    Stack,
    IconButton,
    FormControl,
    InputAdornment,
    FormHelperText,
    useMediaQuery,
    Button,
    
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useValidationSchemas } from "common/utils/schemas";
import { ToggleChangePasswordModal, ToggleSuccessModal } from "services/modules/modals/Actions";
import { UpdateProfileRequest } from "services/modules/auth"; 
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";

const ChangePasswordModal = ({ t }) => {
    const { isOpen } = useSelector((state) => state.modals.changePassword);
    const isWeb = useMediaQuery("(min-width: 768px)");
    const { changePasswordSchema } = useValidationSchemas();

    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const onClose = () => {
        dispatch(ToggleChangePasswordModal({ isOpen: false }));
    };



    const handleSubmit =  (values) => {
        console.log("Submitting form with values:", values);
        const requestData = {
            body: {
                user: {
                    password_old: values.password_old,
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                },
            },
            action: () => {
                console.log("Action called after successful update");
                onClose();
                dispatch(
                    ToggleSuccessModal({
                        isOpen: true,
                        message: t("success.password"),
                    })
                );
            },
        };

      
          dispatch(UpdateProfileRequest({ ...requestData }));
          
        
    };

    const formik = useFormik({
        initialValues: {
            password_old: "",
            password: "",
            password_confirmation: "",
        },
        validationSchema: changePasswordSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isWeb ? "40%" : "100%",
        bgcolor: "background.paper",
        borderRadius: "8px",
        boxShadow: 24,
        p: 4,
        gap: "40px",
    };

    const closeButtonStyle = {
        position: "absolute",
        top: "10px",
        left: "10px",
    };

    return (
        <>
            <Modal open={isOpen} onClose={onClose}>
                <Box sx={modalStyle}>
                    <IconButton sx={closeButtonStyle} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        fontSize={{ xs: "18px", sm: "32px" }}
                        textAlign="center"
                        fontWeight="700" sx={{ mb: 2 }}>
                        {t("profile.header2")}
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack sx={{ pb: "16px" , mt: "30px"}}>
                            <Typography sx={{ pb: "16px", fontSize:{ xs: "12px", sm: "20px" } }} fontWeight="500">
                                {t("profile.currentPassword")}
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                                <TextFieldInput
                                    placeholder={t("profile.currentPassword")}
                                    id="password_old"
                                    name="password_old"
                                    type={showPassword ? "text" : "password"}
                                    value={formik.values.password_old}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password_old && Boolean(formik.errors.password_old)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                sx={{ "&:hover": { bgcolor: "transparent" } }}
                                            >
                                                {showPassword ? (
                                                    <VisibilityOutlinedIcon sx={{ color: "black" }} />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {formik.touched.password_old && formik.errors.password_old && (
                                    <FormHelperText>{formik.errors.password_old}</FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        <Stack sx={{ pb: "16px" }}>
                            <Typography sx={{ pb: "16px", fontSize:{ xs: "12px", sm: "20px" }}} fontWeight="500">
                                {t("reset.password")}
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                                <TextFieldInput
                                    placeholder={t("reset.password")}
                                    id="password"
                                    name="password"
                                    type={showNewPassword ? "text" : "password"}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                sx={{ "&:hover": { bgcolor: "transparent" } }}
                                            >
                                                {showNewPassword ? (
                                                    <VisibilityOutlinedIcon sx={{ color: "black" }} />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <FormHelperText>{formik.errors.password}</FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        <Stack sx={{ pb: "16px", mb:"40px" }}>
                            <Typography sx={{ pb: "16px", fontSize:{ xs: "12px", sm: "20px" }}} fontWeight="500">
                                {t("profile.confirmNewPassword")}
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                                <TextFieldInput
                                    placeholder={t("profile.confirmNewPassword")}
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={formik.values.password_confirmation}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                sx={{ "&:hover": { bgcolor: "transparent" } }}
                                            >
                                                {showConfirmPassword ? (
                                                    <VisibilityOutlinedIcon sx={{ color: "black" }} />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                                    <FormHelperText>{formik.errors.password_confirmation}</FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        <Stack  sx={{ mb:"20px" }} >
                            <LoadingBtn
                                type="submit"
                                variant="contained"
                                sx={{ color: "secondary.main", fontWeight: 500, fontSize: "14px" }}
                            >
                                {t("buttons.changePassword")}
                            </LoadingBtn>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default ChangePasswordModal;
