export const linksStyles = {
    container: {
      maxWidth: { xl: "xl", md: "lg" },
      my: { xs: "20px", sm: "100px" },
    },
    stack: {
      flexDirection: { xs: "column", sm: "row" },
      flexWrap: "wrap",
      gap: "20px",
      mt: "70px",
      mb: "40px",
    },
    title: {
      fontSize: "22px",
      fontWeight: "500",
      mt: 2,
    },
    linkBox: {
      width: { xs: "100%", sm: "calc(50% - 10px)" },
      height: "auto",
      border: "1px solid #EFEFEF",
      borderRadius: { xs: "14px", sm: "8px" },
      p: 4,
      flexDirection: { xs: "row", sm: "column" },
      "@media (max-width: 400px)": {
        width: "auto",
      },
    },
    linkImage: {
      top: 16,
      right: 16,
      width: "40px",
      height: "40px",
      marginBottom: "20px",
    },
    linkName: {
      marginBottom: "30px",
      fontSize: { lg: "24px", xs: "18px" },
      fontWeight: "700",
    },
    linkDescription: {
      marginBottom: "40px",
      fontSize: { lg: "20px", xs: "16px" },
      fontWeight: "400",
      color: "textSecondary",
    },
    button: {
      borderRadius: 21,
      color: "secondary.main",
      marginTop: "auto",
    },
  };
  