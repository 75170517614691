import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import { GetLinksRequest } from "services/modules/links";
import { useTranslation } from "react-i18next";
import { linksStyles } from "./Styles";

const Links = () => {
  const dispatch = useDispatch();
  const { important_links } = useSelector((state) => state.links);
  const { t } = useTranslation();
  const isWeb = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    dispatch(GetLinksRequest());
  }, [dispatch]);

  return (
    <Container sx={linksStyles.container}>
      <Stack sx={linksStyles.stack}>
        {!isWeb && (
          <Typography sx={linksStyles.title}>
            {t("header.links.title")}
          </Typography>
        )}
        {important_links.map((link) => (
          <Box
            key={link.id}
            sx={linksStyles.linkBox}
          >
            <Box>
              <img
                src={link.images?.[0]?.image_url}
                alt={link.name}
                style={linksStyles.linkImage}
              />
              <Typography sx={linksStyles.linkName}>
                {link.name}
              </Typography>
            </Box>
            <Box>
              <Typography sx={linksStyles.linkDescription}>
                {link.description}
              </Typography>
              <Button
                variant="contained"
                href={link.link}
                target="_blank"
                sx={linksStyles.button}
              >
                {t("buttons.link")}
              </Button>
            </Box>
          </Box>
        ))}
      </Stack>
    </Container>
  );
};

export default Links;
