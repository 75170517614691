import Types from "./Types";

const INIT_STATE = {
  
  Otp: {
    isOpen: false,
    subHeader: null,
    btnTitle: null,
    action: null,
  },
  phoneInput: {
    isOpen: false,
    btnTitle: null,
    header: null,
    subHeader: null,
  },
  resetPassword: {
    isOpen: false,
  },
  success: {
    isOpen: false,
    message: null,
  },
  logout: {
    isOpen: false,
    header: null,
    subHeader: null,
  },
  terms: {
    isOpen: false,
    header: null,
  },
  privacy:{
    isOpen: false,
    header: null,
  },
  guestMode:{
    isOpen: false,
    header: null,
    subHeader: null,
  },
  legalService:{
    isOpen:false,
    id: null,
  },
   changePassword: {
    isOpen: false,
  },
};

export default function modalReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.TOGGLE_PHONE_INPUT_MODAL: {
      return {
        ...state,
        phoneInput: payload,
      };
    }

    case Types.TOGGLE_OTP_MODAL: {
      return {
        ...state,
        Otp: payload,
      };
    }

    case Types.TOGGLE_RESET_PASSWORD_MODAL: {
      return {
        ...state,
        resetPassword: payload,
      };
    }
    case Types.TOGGLE_SUCCESS_MODAL: {
      return {
        ...state,
        success: payload,
      };
    }
    case Types.TOGGLE_LOGOUT_MODAL: {
      return {
        ...state,
        logout: payload,
      };
    }

    case Types.TOGGLE_TERMS_MODAL: {
      return {
        ...state,
        terms: payload,
      };
    }
    case Types.TOGGLE_PRIVACY_MODAL: {
      return {
        ...state,
        privacy: payload,
      };
    }

    case Types.TOGGLE_GUEST_MODE_MODAL: {
      return {
        ...state,
        guestMode: payload,
      };
    }

    case Types.TOGGLE_LEGAL_SERVICE_MODAL: {
      return {
        ...state,
        legalService: payload,
      };
    }

    case Types.TOGGLE_CHANGE_PASSWORD_MODAL: {
      return {
        ...state,
        changePassword: payload,
      };
    }

    default: {
      return state;
    }
  }
}
