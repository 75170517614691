import Types from "./Types";

const INIT_STATE = {
  isLoading: false,
  contacts: [],
};

export function contactUsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {

    case Types.CONTACT_US_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case Types.GET_CONTACT_US_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contacts: payload?.data?.contacts, 
      };

    default:
      return state;
  }
}
