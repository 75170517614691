import Breadcrumb from "components/Atoms/Breadcrumbs/Breadcrumb";
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { Box, Container } from "@mui/material";
import { ScrollToTop } from "../../Atoms/SharedComponents/ScrollToTop";
import Footer from "./Footer";
import CopyRight from "./CopyRight";

const Layout = () => {
  return (
    <>
      <Box width="100%">
      < ScrollToTop />
        <Navbar />
        <Outlet />
        <Footer />
        <CopyRight />
      </Box>
    </>
  );
};

export default Layout;
