import Types from "./Types";

export const TogglePhoneInputModal = (payload) => ({
  type: Types.TOGGLE_PHONE_INPUT_MODAL,
  payload,
});

export const ToggleOTPModal = (payload) => ({
  type: Types.TOGGLE_OTP_MODAL,
  payload,
});

export const ToggleResetPasswordModal = (payload) => ({
  type: Types.TOGGLE_RESET_PASSWORD_MODAL,
  payload,
});

export const ToggleSuccessModal = (payload) => ({
  type: Types.TOGGLE_SUCCESS_MODAL,
  payload,
});

export const ToggleLogoutModal = (payload) => ({
  type: Types.TOGGLE_LOGOUT_MODAL,
  payload,
});

export const ToggleTermsModal  = (payload) => ({
  type: Types.TOGGLE_TERMS_MODAL,
  payload,
});
export const TogglePrivacyModal  = (payload) => ({
  type: Types.TOGGLE_PRIVACY_MODAL,
  payload,
});

export const ToggleGuestModeModal =(payload) => ({
  type: Types.TOGGLE_GUEST_MODE_MODAL,
  payload,
});

export const ToggleLegalServiceModal =(payload) => ({
  type: Types.TOGGLE_LEGAL_SERVICE_MODAL,
  payload,
});

export const ToggleChangePasswordModal = (payload) => ({
  type: Types.TOGGLE_CHANGE_PASSWORD_MODAL,
  payload,
});