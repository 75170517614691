import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useValidationSchemas } from "common/utils/schemas";
import { useFormik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loginRequest, requestOtp } from "services/modules/auth";
import { TogglePhoneInputModal } from "services/modules/modals/Actions";
import PhoneInput from "components/Atoms/Input/PhoneInput/PhoneInput";
import PhoneInputModal from "components/Atoms/SharedComponents/Modals/PhoneInputModal";
import en from "react-phone-number-input/locale/en.json";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";

const LoginForm = ({ t }) => {
  const { blackTwo, black5 } = useThemePalette();
  const navigate = useNavigate();
  const { loginSchema } = useValidationSchemas();
  const [showPassword, setShowPassword] = useState(false);
  const [searchParam, setSearchParams] = useSearchParams();
  const lang = useSelector((state) => state.language.lang);
  const load = useSelector((state) => state.auth.load);
  const dispatch = useDispatch();

  const handleClick = (path) => {
    navigate(path);
  };

  const initialValues = {
    phone_number: "",
    country_code: "",
    password: "",
  };

  const onSubmit = (values) => {
    const action = (data) => {
      navigate("/");
      formik?.setValues({ ...initialValues });
    };
    const obj = {
      user: {
        phone_number: values?.phone_number,
        country_code: `+${values.country_code}`,
        password: values?.password,
      },
      device: {
        device_type: "android",
      },
    };
    dispatch(loginRequest({ body: obj, action }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: loginSchema,
  });

  const openPhoneInputModal = () => {
    dispatch(
      TogglePhoneInputModal({
        isOpen: true,
        header: t("login.forgetPassword"),
        subHeader: t("login.forgetPasswordSubheader"),
        btnTitle: t("buttons.send"),
        url:  "/auth/passwords/otp",
        fnToDispatch: requestOtp
      })
    );
  };

  return (
    <>
      <PhoneInputModal t={t} />
      <Stack sx={{ gap: "24px" }}>
        <Typography fontSize={{ xs: "18px", sm: "43px" }} fontWeight="700">
          {t("login.login")}
        </Typography>
        <Typography fontSize={{ xs: "20px", sm: "26px" }} fontWeight="400">
          {t("login.subtitle")}
        </Typography>
        {/* <Divider sx={{ border: "1px solid #F5F5F5" }} /> */}
        <Stack component="form" onSubmit={formik?.handleSubmit} sx={{ mt: "20px" }}>
          <Stack sx={{ pb: "16px" }}>
            <Typography
              sx={{ pb: "16px" }}
              color={blackTwo}
              fontSize={{ xs: "12px", sm: "22px" }}
              fontWeight={{ xs: "500", sm: "400" }}
            >
              {t("login.phone")}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <PhoneInput
                id={"phone_number"}
                name={"phone_number"}
                className="normal-textField user-phone"
                selectId={"search-select"}
                inputValue={formik?.values?.phone_number}
                inputType={"number"}
                setPhone={(e) =>
                  formik?.setValues({
                    ...formik?.values,
                    phone_number: e.target.value,
                  })
                }
                placeholder={t("login.phone")}
                labels={en}
                value={formik?.values?.country_code}
                onChange={(value) =>
                  formik?.setValues({
                    ...formik?.values,
                    country_code: value,
                  })
                }
                codePlus={true}
                error={formik?.touched?.phone_number && Boolean(formik?.errors?.phone_number)}
                helperText={formik?.touched?.phone_number && formik?.errors?.phone_number}
              />
            </Stack>
          </Stack>
          <Stack sx={{ pb: "16px" }}>
            <Typography
              sx={{ pb: "16px" }}
              color={blackTwo}
              fontSize={{ xs: "12px", sm: "22px" }}
              fontWeight={{ xs: "500", sm: "400" }}
            >
              {t("login.password")}
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <TextFieldInput
                placeholder={t("login.password")}
                id="outlined-adornment-password"
                onChange={formik?.handleChange}
                name="password"
                type={showPassword ? "text" : "password"}
                value={formik?.values?.password}
                error={formik?.touched?.password && Boolean(formik?.errors?.password)}
                EndAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                      sx={{
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ color: "black" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik?.touched?.password && Boolean(formik?.errors?.password) && (
                <FormHelperText
                  margin="0"
                  sx={{
                    color: "#ef5350",
                    alignItems: "start",
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {formik?.errors?.password}
                </FormHelperText>
              )}
            </FormControl>
            <Button
              dir="ltr"
              sx={{
                ml: "auto",
                color: black5,
                fontWeight: 400,
                fontSize: { xs: "14px", sm: "18px" },
              }}
              onClick={openPhoneInputModal}
            >
              {t("login.forgetPassword")}
            </Button>
          </Stack>
          <Stack>
            <LoadingBtn
              type="submit"
              variant="contained"
              sx={{ color: "secondary.main", borderRadius: "10px" }}
              isLoading={load?.loading && load?.type === "login"}
            >
              {t("buttons.login")}
            </LoadingBtn>
          </Stack>
          <Stack
            direction="row"
            sx={{ m: "auto", width: "fit-content", mt: "20px" }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontWeight="400" fontSize={{ xs: "14px", sm: "21px" }}>
              {t("login.newUser")}
            </Typography>
            <Button
              sx={{
                fontSize:{ xs: "14px", sm: "21px" },
                fontWeight: "700",
              }}
              onClick={() => handleClick("/signup")}
            >
              {t("login.signUp")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default withTranslation()(LoginForm);
