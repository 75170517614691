import { authLoading, authSuccess, OTPSuccess, loginSuccess, logoutSuccess, signupSuccess ,GetProfilesSuccess,UpdateProfilesSuccess} from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

export const loginRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "login",
    isLoading: authLoading,
    successFunction: loginSuccess,
    url: `/auth/sessions`,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const logoutRequest = () => {
  const requestData = {
    loadingType: "logout",
    isLoading: authLoading,
    successFunction: logoutSuccess,
    url: `/auth/sessions`,
    method: "delete",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const requestOtp = ({ ...variables }) => {
  const requestData = {
    loadingType: "requestOTP",
    isLoading: authLoading,
    successFunction: authSuccess,
    url: variables?.url,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const verifyOtp = ({ ...variables }) => {
  const requestData = {
    loadingType: "verifyOTP ",
    isLoading: authLoading,
    successFunction: OTPSuccess,
    url: variables?.url,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const resetPasswordRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "resetPassword",
    isLoading: authLoading,
    successFunction: authSuccess,
    url: `/auth/passwords/reset`,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const signupRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "signup",
    isLoading: authLoading,
    successFunction: signupSuccess,
    url: "/auth/users/accounts",
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetProfileRequest = (token) => {
  return async (dispatch) => {
    const requestData = {
      isLoading: authLoading,
      successFunction: GetProfilesSuccess,
      loadingType: "list profile",
      url: "/users/profile",
      method: "get",
    
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdateProfileRequest = ({  ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: UpdateProfilesSuccess,
    loadingType: "update profile",
    url: `/users/profile`,
    data: variables?.body,
    action: variables?.action,
    method: "put",
    
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
