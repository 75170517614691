import Types from "./Types";
export const authSuccess = (payload) => ({
  type: Types.AUTH_SUCCESS,
  payload,
});

export const OTPSuccess = (payload) => ({
  type: Types.OTP_SUCCESS,
  payload,
});

export const authLoading = (payload) => ({
  type: Types.AUTH_LOADING,
  payload,
});

export const loginSuccess = (payload) => ({
  type: Types.LOGIN_SUCCESS,
  payload,
});

export const logoutSuccess = (payload) => ({
  type: Types.LOGOUT_SUCCESS,
});

export const signupSuccess = (payload) => ({
  type: Types.SIGN_UP_SUCCESS,
  payload,
});

export const setTokenSuccess = (payload) => ({
  type: Types.SET_TOKEN_SUCCESS,
  payload,
});


export const GetProfilesSuccess = (payload) => ({
  type: Types.GET_PROFILE_SUCCESS,
  payload,
});


export const UpdateProfilesSuccess = (payload) => ({
  type: Types.UPDATE_PROFILE_SUCCESS,
  payload,
});