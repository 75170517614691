import Types from "./Types";

const INIT_STATE = {
  userData: {
    city: "",
    street: "",
    resume: "",
  },
  isLoading: false,
};

export function careersReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.CAREERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case Types.CAREERS_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userData: payload,
      };

    case Types.GET_CAREERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userData: {
          city: payload.data.city,
          street: payload.data.street,
          resume: payload.data.resume_url,
        },
      };

    default:
      return state;
  }
}
