export const contactUsStyles = {
    container: {
        maxWidth: { xl: "xl", md: "lg" },
        display: "flex",
        my: { xs: "20px", sm: "100px" },
    },
    stack: {
      width: "100%",
      flexDirection: { xs: "column", sm: "row" },
      gap: { xs: "20px", sm: "239px" },
    },
    title: {
      fontSize: "22px",
      fontWeight: "500",
      mt: 8,
    },
    cardContainer: {
      maxWidth: { xl: "xl", md: "lg" },
    },
    card: {
      border: "1px solid #EFEFEF",
      borderRadius: "14px",
      padding: "12px",
    },
    imageContainer: {
      width: { xs: "100%", sm: "45%" },
      height: "100%",
      m: 0,
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "20px",
    },
    content: {
      gap: 2,
      mt: "21px",
    },
    header: {
      fontWeight: "700",
      fontSize: { xs: "20px", md: "22px", lg: "24px" },
      gutterBottom: true,
    },
    contactItem: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    contactImage: {
      width: 28,
      height: 28,
      backgroundColor: "#3B3B3B",
      borderRadius: "10px",
      padding: "5px",
    },
    contactText: {
      fontSize: { xs: "14px", lg: "20px" },
      fontWeight: "500",
    },
    subHeader: {
      fontWeight: "700",
      fontSize: { xs: "18px", md: "20px", lg: "22px" },
    },
    divider: {
      backgroundColor: "#C8C8C8",
    },
    socialIcons: {
      display: "inline-block",
      "&:hover": {
        transform: "scale(1.1)",
        transition: "transform 0.2s",
      },
    },
    socialImage: {
      width: 24,
      height: 24,
      filter: "invert(1)",
    },
  };
  