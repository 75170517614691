const authTypes = {
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_LOADING: "AUTH_LOADING",
  OTP_SUCCESS: "OTP_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SET_TOKEN_SUCCESS: "SET_TOKEN_SUCCESS",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS"

};

export default authTypes;
