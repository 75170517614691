import { combineReducers } from "redux";
import modalReducer from "./modules/modals/Reducer";
import languageReducer from "./modules/language/Reducer";
import authReducer from "./modules/auth/Reducer";
import { servicesReducer } from "./modules/legalServices/Reducer";
import { bookReducer } from "./modules/books/Reducer";
import { partnersReducer } from "./modules/partners/Reducer";
import { aboutUsReducer } from "./modules/aboutUs/Reducer";
import { specialitiesReducer } from "./modules/specialities/Reducer";
import { contactUsReducer } from "./modules/contactUs/Reducer";
import { teamReducer } from "./modules/teamWork/Reducer";
import { termsAndConditionsReducer } from "./modules/termsAndConditions/Reducer";
import { linksReducer } from "./modules/links/Reducer";
import { careersReducer } from "./modules/careers/Reducer";
import filesUploadReducer from "./modules/uploadFiles/Reducer";

const rootReducer = combineReducers({
  language: languageReducer,
  modals: modalReducer,
  auth: authReducer,
  services: servicesReducer,
  specialities: specialitiesReducer,
  books: bookReducer,
  partners: partnersReducer,
  aboutUs: aboutUsReducer,
  contactUs: contactUsReducer,
  teamWork: teamReducer,
  termsAndConditions: termsAndConditionsReducer,
  links: linksReducer,
  careers: careersReducer,
  uploadFile: filesUploadReducer,
});

export default rootReducer;
