import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import ServiceCard from "components/Molecules/Home/ServiceCard";
import { useSelector, useDispatch } from "react-redux";
import GuestModeModal from "components/Atoms/SharedComponents/Modals/GuestModeModal";
import { useTranslation } from "react-i18next";
import { GetServicesRequest } from "services/modules/legalServices";
import LegalServiceModal from "components/Atoms/SharedComponents/Modals/LegalServiceModal";
import { useNavigate, useLocation } from "react-router-dom";
import { icons } from "assets/AssetHelper";
// import SectionLoader from "components/Atoms/Loader/SectionLoader";

const Services = () => {
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.services);
  const { t } = useTranslation();
  const limitedServices = services.slice(0, 8);
  const navigate = useNavigate();
  const isWeb = useMediaQuery("(min-width: 768px)");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      await dispatch(GetServicesRequest());
      setLoading(false);
    };

    fetchServices();
  }, [dispatch]);

  const handleSeeMore = () => {
    navigate("/legal-services/");
  };

  return (
    <>
      <LegalServiceModal t={t} />
      <GuestModeModal t={t} />
      <Container sx={{ maxWidth: { xl: "xl", md: "lg" }, mt: { xs: "80px", sm: 0 } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "48px",
          }}
        >
          <Typography
            fontSize={{ xs: "22px", md: "30px", lg: "40px" }}
            fontWeight={{ xs: "500", sm: "700" }}
          >
            {isWeb ? t("home.legal services.title"):t("home.pages.legalServices") }
          </Typography>
        
          {isWeb && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ borderRadius: 21 }}
              onClick={handleSeeMore}
            >
              {t("buttons.seeMore")}
            </Button>
          )}
        </Box>
        <Box sx={{ position: 'relative', minHeight: '300px' }}>
          {/* <SectionLoader open={loading} /> */}
          {!loading && (
            <Grid container columnSpacing={2} rowGap={{ xs: 3, sm: 6 }}>
              {limitedServices.map((service) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={service.id}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <ServiceCard service={service} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        {!isWeb && (
          <Box display="flex" justifyContent="flex-end" sx={{ mt: "24px" }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ borderRadius: 21 }}
              onClick={handleSeeMore}
            >
              {t("buttons.seeMore")}
            </Button>
          </Box>
        )}
       
      </Container>
    </>
  );
};

export default Services;
