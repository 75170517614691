import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Modal, Stack, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useThemePalette } from "common/hooks/theme_palette";
import { useTimer } from "common/hooks/useTimer";
import { useValidationSchemas } from "common/utils/schemas";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VerificationInput from "react-verification-input";
import { requestOtp, verifyOtp, UpdateProfileRequest } from "services/modules/auth"; 
import { setTokenSuccess } from "services/modules/auth/Actions";
import {
  ToggleOTPModal,
  ToggleResetPasswordModal,
  ToggleSuccessModal,
} from "services/modules/modals/Actions";
import ResetPasswordModal from "./ResetPasswordModal";
import "./styles.css";
import SuccessModal from "./SucessModal";

const OTPModal = ({ t, sendUrl }) => {
  const { blackTwo, babyBlue } = useThemePalette();
  const isWeb = useMediaQuery("(min-width: 768px)");
  const path = location.pathname;
  const dispatch = useDispatch();
  const [resendButtonEnabled, setResendButtonEnabled] = useState(false);
  const load = useSelector((state) => state.auth.load);
  const { verificationSchema } = useValidationSchemas();

  const onClose = () => {
    formik.resetForm();
    dispatch(
      ToggleOTPModal({
        isOpen: false,
        subHeader: null,
        btnTitle: null,
      })
    );
  };

  const handleTimerEnd = () => {
    setResendButtonEnabled(true);
  };

  const { seconds, resetTimer, startTimer } = useTimer(handleTimerEnd, 70);
  const { isOpen, btnTitle, subHeader, phoneNumber, countryCode, verifyUrl } = useSelector(
    (state) => state.modals.Otp
  );

  useEffect(() => {
    if (isOpen) {
      startTimer();
    }
  }, [isOpen, startTimer]);

  const actions = {
    "/profile": () => {
      dispatch(ToggleSuccessModal({ isOpen: true, message: t("success.password") }));
    },
    "/login": () => {
      dispatch(ToggleResetPasswordModal({ isOpen: true, phoneNumber, countryCode }));
    },
    "/signup": () => {
      dispatch(ToggleSuccessModal({ isOpen: true, message: t("success.signup") }));
    },
  };

  const { userData } = useSelector((state) => state.auth);
  const handleSubmit = (values) => {
    const action = () => {
      const actionToDispatch = actions[path];
      onClose();
      actionToDispatch && actionToDispatch();
      const token = axios.defaults.headers.common["Authorization"];
      if (token) {
        dispatch(setTokenSuccess(token));
      }
    };

    const user =
      "/profile" == path
        ? { otp: values.otp }
        : {
            phone_number: phoneNumber,
            country_code: countryCode,
            otp: values.otp,
          };
    const requestData = {
      body: {
        user,
      },
      url: verifyUrl,
      action,
    };

    dispatch(verifyOtp({ ...requestData }));
  };

  const handleResendEmail = () => {
    const requestData = {
      body: {
        user: {
          phone_number: phoneNumber,
          country_code: countryCode,
        },
      },
      url: sendUrl,
    };

    if (path === "/profile") {
      dispatch(UpdateProfileRequest({ ...requestData }));
    } else {
      dispatch(requestOtp({ ...requestData }));
    }
    resetTimer();
    setResendButtonEnabled(false);
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: verificationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validateOnBlur: true,
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isWeb ? "40%" : "100%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    gap: "40px",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    left: "10px",
  };

  return (
    <>
      <ResetPasswordModal t={t} phoneNumber={phoneNumber} countryCode={countryCode} />
      <SuccessModal t={t} />
      <Modal open={isOpen} onClose={onClose}>
        <Stack sx={modalStyle}>
          <IconButton sx={closeButtonStyle} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography fontSize={{ xs: "18px", sm: "32px" }} textAlign="center" fontWeight="700">
            {t("otp.header")}
          </Typography>
          <Typography fontSize={{ xs: "16px", sm: "26px" }} textAlign="center" color={blackTwo}>
            {subHeader}
          </Typography>
          {/* OTP TEST */}
          <Typography
            variant="h2"
            fontSize="20px"
            textAlign="center"
            color="error"
            sx={{ fontWeight: 700 }}
          >
            {userData?.otp}{" "}
          </Typography>

          <Stack component="form" onSubmit={formik.handleSubmit}>
            <Stack justifyContent="center" alignItems="center" sx={{ marginBottom: "40px" }}>
              <VerificationInput
                length={4}
                validChars="0-9"
                placeholder=""
                onChange={(value) => formik.setFieldValue("otp", value)}
                name="otp"
                value={formik.values.otp}
                autoFocus
                classNames={{
                  container: "verification-container",
                  character: "character",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
              />
              {formik.touched.otp && formik.errors.otp && (
                <Typography variant="body2" sx={{ color: "#ef5350", mt: 1 }}>
                  {formik.errors.otp}
                </Typography>
              )}
            </Stack>
            <Stack justifyContent="center" alignItems="center" spacing={2}>
              <LoadingBtn
                type="submit"
                variant="contained"
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  width: "100%",
                }}
                isLoading={load?.loading && load?.type === "verifyOTP"}
                disabled={!formik.values.otp}
              >
                {btnTitle}
              </LoadingBtn>
              <Stack
                direction="row"
                sx={{ m: "auto", width: "fit-content", mt: "32px", mb: "10px" }}
                justifyContent="center"
                alignItems="center"
              >
                <Typography color={babyBlue} fontWeight="300" fontSize="14px">
                  {t("otp.arrival")}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: blackTwo,
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                  onClick={handleResendEmail}
                  disabled={!resendButtonEnabled}
                >
                  {t("otp.resend")}
                </Button>
              </Stack>
              <Typography fontWeight="600" color={blackTwo}>
                {`${seconds} ${t("otp.sec")}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default OTPModal;
