import Types from "./Types";

export const ConditionLoading = (payload) => ({
  type: Types.CONDITION_LOADING,
  payload,
});

export const GetTermsSuccess = (payload) => ({
  type: Types.GET_TERMS_SUCCESS,
  payload,
});

export const GetPrivacySuccess = (payload) => ({
  type: Types.GET_PRIVACY_SUCCESS,
  payload,
});


