import Header from "components/Atoms/SharedComponents/Header";
import TeamWork from "components/Organisms/teamwork/TeamWork";
import React from "react";
import { withTranslation } from "react-i18next";

const TeamWorkTemplate = ({ t }) => {
  return (
    <>
    <Header 
     title={t("header.teamWork.title")}
     subtitle={t("header.teamWork.subtitle")}
    />
    <TeamWork />
    </> 
  );
}
export default withTranslation()(TeamWorkTemplate);
