import React from 'react';
import { Button, CircularProgress, Typography } from "@mui/material";

export default function LoadingBtn({ isLoading = false, disabled = false, children, ...rest }) {
  return (
    <Button {...rest} disabled={isLoading || disabled}>
      {isLoading ? (
        <CircularProgress color="inherit"  />
      ) : (
        <Typography sx={{ py: 1,color: "secondary.main", borderRadius: "10px" ,fontWeight:  { xs: "500", sm: "700" } ,fontSize:{xs:"14px", sm:"22px"}}}>{children}</Typography>
      )}
    </Button>
  );
}
