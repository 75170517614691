import { Button, Container } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { icons } from 'assets/AssetHelper';

export default function WhatsIcon() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = () => {
        window.open('https://web.whatsapp.com', '_blank');
    };

    return (
        <Container sx={{ maxWidth: { xl: "xl", md: "lg" }, display: "flex", mt: 10 }}>
        <Button
            sx={{
                position: 'fixed',
                zIndex: 999,
                left: '15px',
                bottom: '10px',
                padding: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
                },
            }}
            aria-label='whatsapp icon'
            onClick={handleClick}
        >
            <img 
                src={isSmallScreen ? icons.WhatsApp : icons.Whatsapp} 
                alt='whatsapp icon'
                style={{ width: isSmallScreen ? '50px' : '80px', height: isSmallScreen ? '50px' : '80px' }}
            />
        </Button>
        </Container>
    );
}
