import React from "react";
import { Box, Button, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

const Header = ({ t, title, subtitle, buttonText }) => {
  const { lang } = useSelector((state) => state.language);
  const theme = useTheme();
  const { secondaryColor } = useThemePalette();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const path = location.pathname;

  if (isSmallScreen) {
    return null;
  }

  const handleButtonClick = () => {
    navigate("/legal-services");
  };

  return (
    <Stack
      sx={{
        backgroundColor: "black",
        color: "white",
        textAlign: "center",
        p: path === "/" ? "80px" : "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: lang === "ar" ? "row-reverse" : "row",
      }}
    >
      <Container
        sx={{
          maxWidth: { xl: "xl", md: "lg" },
          mt:path === "/" ? "20px" : "100px",
          // mb: path === "/" ? "86px" : "25px",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            gap: path === "/" ? "15px" : "200px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              p: path === "/" ? "0px" : "40px",
              textAlign: lang === "ar" ? "right" : "left",
            }}
          >
            <Typography fontSize={{ lg: "40px", md: "30px"}} width="637px" fontWeight="700">
              {title}
            </Typography>
            {subtitle && (
              <Typography
                fontSize={{ lg: "24px", md: "20px"}}
                sx={{
                  width: "556px",
                  mt: path === "/" ? "41px" : "7px",
                  mb: path === "/" ? "0px" : "25px"
                }}
              >
                {subtitle}
              </Typography>
            )}
            {buttonText && (
              <Button
              variant="contained"
              sx={{
                color: "black",
                fontWeight: 500,
                bgcolor: secondaryColor,
                mt: "41px",
                fontSize: { lg: "16px", md: "14px", sm: "12px", xs: "10px" },
                '&:hover': {
                  bgcolor: secondaryColor, 
                  opacity: 0.8, 
                },
              }}
              onClick={handleButtonClick}
            >
              {buttonText}
            </Button>
            )}
          </Box>
          <Box component="figure" sx={{ width: "100%" }}>
            <Box
              component={"img"}
              src={images.HeaderLogo}
              alt="almasah-logo"
              sx={{
                maxWidth: path === "/" ? "100%" : "90%",
              }}
            />
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default withTranslation()(Header);
