import { withTranslation } from "react-i18next";
import BooksTemplate from "templates/books/Books.Template";

const BooksPage = ({ t }) => {
  return (
    <>
      <BooksTemplate t={t} />
    </>
  );
};

export default withTranslation()(BooksPage);
