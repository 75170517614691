import Types from "./Types";

const INIT_STATE = {
  isLoading: false,
  specialities: [],
};

export function specialitiesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SPECIALTIES_LOADING:
      return {
        ...state,
        isLoading: true,
      };

      case Types.GET_SPECIALITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        specialities: payload?.data?.specialities , 
      };


    default:
      return state;
  }
}
