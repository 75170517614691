import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Box,
  Typography,
  Stack,
  Button,
  IconButton,
  FormControl,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useThemePalette } from "common/hooks/theme_palette";
import { ToggleResetPasswordModal } from "services/modules/modals/Actions";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import SuccessModal from "./SucessModal";
import { ToggleSuccessModal } from "services/modules/modals/Actions";
import axios from "axios";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import { useValidationSchemas } from "common/utils/schemas";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import { resetPasswordRequest } from "services/modules/auth";

const ResetPasswordModal = ({ t}) => {
  const resetToken = useSelector((state) => state.auth.resetToken);
  const { isOpen, phoneNumber, countryCode  } = useSelector(
    (state) => state.modals.resetPassword
  );
  const isWeb = useMediaQuery("(min-width: 768px)");
  const { resetPasswordSchema } = useValidationSchemas();


  const dispatch = useDispatch();
  const { blackTwo, babyBlue } = useThemePalette();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const load = useSelector((state) => state.auth.load);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const onClose = () => {
    dispatch(ToggleResetPasswordModal({ isOpen: false }));
  };

  const handleSubmit = (values) => {
    axios.defaults.headers.common["verification-token"] = resetToken;
    const action = () => {
      delete axios.defaults.headers.common["verification-token"];
      onClose();
      dispatch(
        ToggleSuccessModal({
          isOpen: true,
          message: t("success.password"),
        })
      );
    };

    const requestData = {
      body: {
        user: {
          phone_number: phoneNumber,
          country_code: countryCode,
          password: values.password,
          password_confirmation: values.password_confirmation,
        },
      },
      action,
    };
    dispatch(resetPasswordRequest({ ...requestData }));
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isWeb ? "40%" : "100%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    gap: "40px",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    left: "10px",
  };

  return (
    <>
      <SuccessModal t={t} />
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={modalStyle}>
          <IconButton sx={closeButtonStyle} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography 
            fontSize={{ xs: "18px", sm: "32px" }}
            textAlign="center" 
            fontWeight="700" sx={{ mb: 2 }}>
            {t("reset.header")}
          </Typography>
          <Typography fontSize={{ xs: "16px", sm: "26px" }} textAlign="center" fontWeight="400">
            {t("reset.subHeader")}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ pb: "16px" }}>
              <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
                {t("reset.password")}
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <TextFieldInput
                  placeholder={t("reset.password")}
                  id="password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ "&:hover": { bgcolor: "transparent" } }}
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon sx={{ color: "black" }} />
                        ) : (
                          <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.password && Boolean(formik.errors.password) && (
                  <FormHelperText
                    margin="0"
                    sx={{
                      color: "#ef5350",
                      alignItems: "start",
                      marginTop: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {formik.errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack sx={{ pb: "16px" }}>
              <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
                {t("reset.confirm")}
              </Typography>
              <FormControl variant="outlined">
                {/* <OutlinedInput
                  placeholder={t("reset.confirm")}
                  id="password_confirmation"
                  name="password_confirmation"
                  type={showConfirmPassword ? "text" : "password"}
                  value={formik.values.password_confirmation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password_confirmation &&
                    Boolean(formik.errors.password_confirmation)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ "&:hover": { bgcolor: "transparent" } }}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOutlinedIcon sx={{ color: "black" }} />
                        ) : (
                          <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                /> */}
                <TextFieldInput
                  placeholder={t("reset.confirm")}
                  id="password_confirmation"
                  name="password_confirmation"
                  type={showConfirmPassword ? "text" : "password"}
                  value={formik.values.password_confirmation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password_confirmation &&
                    Boolean(formik.errors.password_confirmation)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ "&:hover": { bgcolor: "transparent" } }}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOutlinedIcon sx={{ color: "black" }} />
                        ) : (
                          <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.password_confirmation &&
                  Boolean(formik.errors.password_confirmation) && (
                    <FormHelperText
                      margin="0"
                      sx={{
                        color: "#ef5350",
                        alignItems: "start",
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {formik.errors.password_confirmation}
                    </FormHelperText>
                  )}
              </FormControl>
            </Stack>
            <Stack>
              <LoadingBtn
                type="submit"
                variant="contained"
                sx={{ color: "secondary.main", fontWeight: 500, fontSize: "14px" }}
                isLoading={load?.loading && load?.type === "resetPassword"}
              >
                {t("buttons.changePassword")}
              </LoadingBtn>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ResetPasswordModal;
