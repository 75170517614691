import Header from "components/Atoms/SharedComponents/Header";
import Links from "components/Organisms/links/Links";
import React from "react";

export default function LinksTemplate({  t }) {
  return (
    <>
      <Header 
     title={t("header.links.title")}
     subtitle={t("header.links.subtitle")}
    />
    <Links />
    </>
  );
}
