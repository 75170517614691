import "./App.css";
import React, { useState, useEffect } from "react";
import AppThemeProvider from "./common/styles/theme";
import { I18nextProvider } from "react-i18next";
import i18n from "common/i18n";
import { store } from "./services/store";
import { Provider as ReduxProvider, useDispatch } from "react-redux";
import AppRoute from "./common/Route";
import AlertProvider from "./components/Atoms/Alerts/ToastifyAlert";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setLanguage } from "services/modules/language/Actions";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const language = localStorage.getItem("i18nextLng") || "en";
    document.documentElement.lang = language;
    dispatch(setLanguage(language));
  }, []);
  return (
    <I18nextProvider i18n={i18n}>
      <ReduxProvider store={store}>
        <AppThemeProvider>
          <AlertProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AppRoute />
            </LocalizationProvider>
          </AlertProvider>
        </AppThemeProvider>
      </ReduxProvider>
    </I18nextProvider>
  );
}

export default App;
