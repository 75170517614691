import Profile from "components/Organisms/profile/Profile";
import React from "react";
import { withTranslation } from "react-i18next";

const ProfileTemplate = ({ t }) => {
  return (
    <>
    <Profile />
    </> 
  );
}
export default withTranslation()(ProfileTemplate);
