import Types from "./Types";

export const GetServicesSuccess = (payload) => ({
  type: Types.GET_SERVICES_SUCCESS,
  payload,
});

export const ServicesLoading = (payload) => ({
  type: Types.SERVICES_LOADING,
  payload,
});

export const ServiceRequestSuccess = (payload) => ({
  type: Types.SERVICE_REQUEST_SUCCESS ,
  payload,
});