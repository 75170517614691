import Types from "./Types";

export const GetPartnersSuccess = (payload) => ({
  type: Types.GET_PARTNERS_SUCCESS,
  payload,
});

export const PartnersLoading = (payload) => ({
  type: Types.PARTNERS_LOADING,
  payload,
});
