import { withTranslation } from "react-i18next";
import TeamWorkTemplate from "templates/teamwork/TeamWork.Template";

const TeamWorkPage = ({ t }) => {

  return (
    <>
      
        <TeamWorkTemplate t={t}/>
  
    </>
  );
};

export default withTranslation()(TeamWorkPage);
