import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Container, Typography, useMediaQuery } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useTranslation } from "react-i18next";
import { GetFullArticlesRequest } from "services/modules/aboutUs";
import AboutUsFullArticle from "components/Molecules/AboutUs/AboutUsFullArticle";

const About = () => {
  const dispatch = useDispatch();
  const { articles } = useSelector((state) => state.aboutUs);
  const { t } = useTranslation();
  const isWeb = useMediaQuery("(min-width: 768px)");

  const getArticles = () => {
    const requestData = {
      article: "main_about",
    };
    dispatch(GetFullArticlesRequest(requestData));
  };

  useEffect(() => {
    getArticles();
  }, [dispatch]);

  const renderAboutContent = () => (
    <Stack width="100%" flexDirection={{ xs: "column", sm: "row" }} gap="16px">
      {!isWeb && (
        <>
          <Stack component="figure" sx={{ width: { xs: "100%", sm: "50%" }, height: "100%"}}>
            <Box
              component="img"
              src={images.AboutUsFullImg}
              alt="aboutus-img"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          </Stack>
          <Stack sx={{ flex: 2, width: { xs: "100%", sm: "50%" } }}>
            <AboutUsFullArticle article={articles} />
          </Stack>
        </>
      )}
      {isWeb && (
        <>
          <Stack sx={{ flex: 2, width: { xs: "100%", sm: "50%" } }}>
            <AboutUsFullArticle article={articles} />
          </Stack>
          <Stack component="figure" sx={{ width: { xs: "100%", sm: "50%" }, m: 0 }}>
            <Box
              component="img"
              src={images.AboutUsFullImg}
              alt="aboutus-img"
              sx={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          </Stack>
        </>
      )}
    </Stack>
  );

  return (
    <Container sx={{ maxWidth: { xl: "xl", md: "lg" }, my: "90px" }}>
      {!isWeb ? (
        <>
          <Typography fontSize="22px" mt="50px"  mb="20px" gutterBottom>
            {t("home.aboutUs.title")}
          </Typography>
          <Box sx={{ border: "1px solid #EFEFEF", borderRadius: "14px", padding: "12px" }}>
            {renderAboutContent()}
          </Box>
        </>
      ) : (
        renderAboutContent()
      )}
    </Container>
  );
};

export default About;
