import React from 'react';
import { Card, CardMedia, Typography, Box } from '@mui/material';

const PartnerCard = ({ partner }) => {

  return (
    
    <Box sx={{ width: '100%', textAlign: 'center', mb: 2 }}> 
      <Card
        sx={{
          width: '100%',
          maxWidth: 280, 
          height: 170, 
          boxShadow: 3,
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'translateY(-5px)', 
          },
        }}
      >
        <CardMedia
          component="img"
          image={partner.images[0].image_url}
          alt={partner.name}
          sx={{
            height: '100%', 
            objectFit: 'cover',
          }}
        />
      </Card>
      <Typography 
        variant="h6" 
        sx={{ 
          mt: 2, 
          fontSize: 24, 
          maxWidth: 280, 
          overflow: 'hidden', 
          textOverflow: 'ellipsis', 
        }}
      >
        {partner?.name} 
      </Typography>
    </Box>
  );
};

export default PartnerCard;
