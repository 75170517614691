import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import ServiceCard from "components/Molecules/Home/ServiceCard";
import { useSelector, useDispatch } from "react-redux";
import GuestModeModal from "components/Atoms/SharedComponents/Modals/GuestModeModal";
import { useTranslation } from "react-i18next";
import { GetServicesRequest } from "services/modules/legalServices";
import LegalServiceModal from "components/Atoms/SharedComponents/Modals/LegalServiceModal";

const Services = () => {
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.services);
  const { t } = useTranslation();
  const isWeb = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    dispatch(GetServicesRequest());
  }, [dispatch]);

  const renderServiceCards = () => (
    <Grid container columnSpacing={2} rowGap={{ xs: 3, sm: 6 }}>
      {services.map((service) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          key={service.id}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <LegalServiceModal t={t} />
      <GuestModeModal t={t} />
      <Container sx={{ maxWidth: { xl: "xl", md: "lg" }, my: "70px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "20px" }}>
          {!isWeb && (
            <Typography fontSize="22px" fontWeight="500" mt="16px" gutterBottom>
              {t("header.services.title")}
            </Typography>
          )}
          {isWeb && (
            <Typography
              width="70%"
              fontSize={{  md: "30px", lg: "40px" }}
              fontWeight={{ xs: 500, md: 700 }}
              mt={2}
            >
              {t("home.legal services.title")}
            </Typography>
          )}
        </Box>
        {!isWeb ? (
          <Box sx={{ border: "1px solid #EFEFEF", borderRadius: "14px", padding: "12px" }}>
            {renderServiceCards()}
          </Box>
        ) : (
          renderServiceCards()
        )}
      </Container>
    </>
  );
};

export default Services;
