import Types from "./Types";

const INIT_STATE = {
  books: [],
};

export function bookReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.BOOKS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case Types.GET_BOOKS_SUCCESS:
      return {
        ...state,
        books: payload?.data?.books, 
      };
    default:
      return state;
  }
}
