import { withTranslation } from "react-i18next";
import ContactUsTemplate from "templates/contactus/ContactUs.Template";

const ContactUsPage = ({ t }) => {

  return (
    <>
      
        <ContactUsTemplate t={t}/>
  
    </>
  );
};

export default withTranslation()(ContactUsPage);
