import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetBooksRequest } from "services/modules/books";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import BookCard from "components/Molecules/Home/BookCard";
import GuestModeModal from "components/Atoms/SharedComponents/Modals/GuestModeModal";
import { useNavigate } from "react-router-dom";
import Loader from "components/Atoms/Loader/Loader";

const OurBooks = () => {
  const dispatch = useDispatch();
  const { books } = useSelector((state) => state.books);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const load = useSelector((state) => state.auth.load);
  const isWeb = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    dispatch(GetBooksRequest());
  }, [dispatch]);

  const handleSeeMore = () => {
    navigate("/our-books/");
  };
  const limitedbooks = books.slice(0, 4);

  return (
    <>
      <Loader open={load?.loading} />
      <GuestModeModal t={t} />
      <Container sx={{ maxWidth: { xl: "xl", md: "lg" } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "48px" }}>
          <Typography
            fontSize={{ xs: "22px", md: "30px", lg: "40px" }}
            fontWeight={{ xs: "500", sm: "700" }}
          >
            {t("home.books.title")}
          </Typography>
          {isWeb && (
            <Button
            variant="outlined"
            color="primary"
            sx={{ borderRadius: 21 }}
            onClick={handleSeeMore}
          >
            {t("buttons.seeMore")}
          </Button>
          )}
        
        </Box>
        <Grid container spacing={{ xs: 0, md: 6 }} rowGap={{ xs: "35px", md: 0 }}>
          {limitedbooks.map((book) => (
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              key={book.id}
              sx={{ justifyContent: "center", alignItems: "stretch" }}
            >
              <BookCard book={book} />
            </Grid>
          ))}
        </Grid>
        {!isWeb && (
          <Box  display="flex" justifyContent="flex-end" sx={{ mt: "24px"}}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ borderRadius: 21 ,}}
              onClick={handleSeeMore}
            >
              {t("buttons.seeMore")}
            </Button>
          </Box>
        )}
      </Container>
    </>
  );
};

export default OurBooks;
