import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import PhoneInput from "components/Atoms/Input/PhoneInput/PhoneInput";
import en from "react-phone-number-input/locale/en.json";
import { useThemePalette } from "common/hooks/theme_palette";
import OTPModal from "./OTPModal";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  ToggleOTPModal,
  TogglePhoneInputModal,
} from "services/modules/modals/Actions";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import { useValidationSchemas } from "common/utils/schemas";
import { useLocation } from "react-router-dom";

const PhoneInputModal = ({ t }) => {
  const { blackTwo } = useThemePalette();
  const { isOpen, btnTitle, header, subHeader, fnToDispatch, url } = useSelector(
    (state) => state.modals.phoneInput
  );
  const token = useSelector((state) => state.auth.userData.token);
  const dispatch = useDispatch();
  const load = useSelector((state) => state.auth.load);
  const { phoneValidationSchema } = useValidationSchemas();
  const location = useLocation();
  const path = location.pathname;

  const onClose = () => {
    formik.resetForm();
    dispatch(
      TogglePhoneInputModal({
        isOpen: false,
        header: null,
        subHeader: null,
        btnTitle: null,
      })
    );
  };

  const handleSubmit = (values) => {
    const action = () => {
      onClose();
      dispatch(
        ToggleOTPModal({
          isOpen: true,
          subHeader: t("otp.subHeader3"),
          btnTitle:  path === "/profile" ? t("buttons.done") : t("buttons.next"),
          verifyUrl:
            path === "/profile" ? "/auth/verifications/otp/verify" : "/auth/passwords/verify",
          phoneNumber: formik.values.phone_number,
          countryCode: `+${formik.values.country_code}`,
        })
      );
    };

    const requestData = {
      body: {
        user: {
          phone_number: values.phone_number,
          country_code: `+${values.country_code}`,
        },
      },
      url,
      action,
    };
    dispatch(fnToDispatch({ ...requestData }));
  };

  const formik = useFormik({
    initialValues: {
      phone_number: "",
      country_code: "20",
    },
    validationSchema: phoneValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: false,
  });

  const isWeb = useMediaQuery("(min-width: 768px)");
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isWeb ? "40%" : "100%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    gap: "20px",
  };
  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    left: "10px",
  };

  return (
    <>
      <OTPModal t={t} sendUrl="/auth/passwords/otp" path="/login" />
      <Modal open={isOpen} onClose={onClose}>
        <Stack sx={modalStyle}>
          <IconButton sx={closeButtonStyle} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography
            fontSize={{ xs: "18px", sm: "32px" }}
            textAlign="center"
            fontWeight="700"
            sx={{ my: "20px" }}
          >
            {header}
          </Typography>
          <Typography fontSize={{ xs: "16px", sm: "26px" }} textAlign="center" fontWeight="400">
            {subHeader}
          </Typography>
          <Stack component="form" sx={{ gap: "40px" }} onSubmit={formik.handleSubmit}>
            <Stack sx={{ gap: "20px" }}>
              <Typography color={blackTwo} fontWeight="500">
                {t("login.phone")}
              </Typography>
              <PhoneInput
                id="phone_number"
                name="phone_number"
                className="normal-textField user-phone"
                selectId="search-select"
                inputValue={formik.values.phone_number}
                inputType="number"
                setPhone={(e) =>
                  formik.setValues({
                    ...formik.values,
                    phone_number: e.target.value,
                  })
                }
                placeholder={t("login.phone")}
                labels={en}
                value={formik.values.country_code}
                onChange={(value) =>
                  formik.setValues({
                    ...formik.values,
                    country_code: value,
                  })
                }
                codePlus={true}
                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                helperText={formik.touched.phone_number && formik.errors.phone_number}
              />
              {formik.touched.phone_number && formik.errors.phone_number && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "#EF5350",
                    mt: 1,
                  }}
                >
                  {formik.errors.phone_number}
                </Typography>
              )}
            </Stack>
            <LoadingBtn
              type="submit"
              variant="contained"
              sx={{
                color: "secondary.main",
                fontWeight: 500,
                fontSize: "14px",
                width: "100%",
              }}
              isLoading={load?.loading && load?.type === "requestOTP"}
            >
              {btnTitle}
            </LoadingBtn>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default PhoneInputModal;
