import Types from "./Types";

const INIT_STATE = {
  isLoading: false,
  teams: [],
};

export function teamReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.TEAM_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case Types.GET_TEAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teams: payload?.data?.teams, 
      };
    default:
      return state;
  }
}
