import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Toolbar,
  IconButton,
  Drawer,
  Button,
  Container,
  Stack,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { withTranslation } from "react-i18next";
import i18n from "common/i18n";
import { icons } from "assets/AssetHelper";
import LogoutModal from "components/Atoms/SharedComponents/Modals/LogoutModal";
import GuestModeModal from "components/Atoms/SharedComponents/Modals/GuestModeModal";
import { ToggleLogoutModal, ToggleGuestModeModal } from "services/modules/modals/Actions";
import { setCurrentLanguage } from "services/modules/language";
import {
  CustomAppBar,
  DrawerHeader,
  appBarStyles,
  buttonStyles,
  drawerButtonStyles,
  profileMenuStyles,
} from "./Styles";
import NavLogo from "../../../assets/images/almasah-nav-logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Navbar = ({ t }) => {
  const { userData } = useSelector((state) => state.auth);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activePath, setActivePath] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.language);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { pathname } = useLocation();

  const pages = [
    { title: t("home.pages.home"), path: "/" },
    { title: t("home.pages.legalServices"), path: "/legal-services" },
    { title: t("home.pages.workTeam"), path: "/team-work" },
    { title: t("home.pages.OurPublications"), path: "/our-books" },
    { title: t("home.pages.employment"), path: "/careers" },
    { title: t("home.pages.links"), path: "/important-links" },
    { title: t("home.pages.about"), path: "/about-us" },
    { title: t("home.pages.contactUs"), path: "/contact-us" },
  ];

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  
  const handleClick = (path) => {
    if (path === "/careers" && !userData?.token) {
      // Open the modal if no token
      dispatch(ToggleGuestModeModal({ 
        isOpen: true, 
        header: t("home.careers.header"),
        subHeader: t("home.careers.subHeader")
        , }));
    } else {
      setActivePath(path);
      navigate(path);
      handleDrawerClose();
    }
  };

  const toggleLanguage = () => dispatch(setCurrentLanguage(lang === "ar" ? "en" : "ar"));
  const handleLogout = () =>
    dispatch(
      ToggleLogoutModal({
        isOpen: true,
        header: t("logout.header"),
        subHeader: t("logout.subHeader"),
      })
    );
  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <>
      <LogoutModal t={t} />
      <GuestModeModal t={t} /> 
      <CustomAppBar position="fixed" open={drawerOpen} sx={appBarStyles(theme, isSmallScreen)}>
        <Box sx={{ width: "100%" }}>
          <Toolbar
            style={{
              paddingRight: 0,
              paddingLeft: 0,
              justifyContent: "center",
              boxShadow: "none",
            }}
          >
            <Container sx={{ maxWidth: { xl: "xl", md: "lg" } }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={{ justifyContent: "space-between" }}
              >
                {isSmallScreen ? (
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={NavLogo}
                      alt="Logo"
                      sx={{ maxHeight: "100%", maxWidth: "100%" }}
                    />
                    <IconButton
                      size="large"
                      aria-label="menu"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleDrawerOpen}
                    >
                      <MenuIcon sx={{ fontSize: "45px", color: "#000" }} />
                    </IconButton>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    spacing={i18n.language === "en" ? 1 : 2}
                    sx={{ gap: "10px", justifyContent: "space-between" }}
                  >
                    {pages.map((page, i) => (
                      <Button
                        disableRipple
                        key={i}
                        onClick={() => handleClick(page.path)}
                        sx={buttonStyles(theme, page.path === activePath)}
                      >
                        {page.title}
                      </Button>
                    ))}
                    {userData?.token ? (
                      <>
                        <Tooltip title="Profile settings">
                          <Button
                            id="fade-button"
                            aria-controls={Boolean(anchorEl) ? "fade-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                            sx={{
                              fontWeight: 500,
                              color: "secondary.main",
                              gap: 1,
                              fontSize: "20px", 
                            }}
                            onClick={handleMenuClick}
                          >
                            <img src={icons?.ProfileIcon} alt="profile icon" />
                            {userData.name}
                          </Button>
                        </Tooltip>

                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          PaperProps={profileMenuStyles(theme).paperProps}
                          transformOrigin={profileMenuStyles(theme).transformOrigin}
                          anchorOrigin={profileMenuStyles(theme).anchorOrigin}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClick("/profile");
                              setAnchorEl(null);
                            }}
                            sx={{ fontWeight: 500 }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                              <img src={icons?.ProfileIcon} alt="profile icon" />
                              {t("buttons.profile")}
                            </Box>
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={handleLogout}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                              <img src={icons?.LogoutIcon} alt="logout icon" />
                              {t("buttons.logout")}
                            </Box>
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <>
                        <Button
                          sx={{ color: theme.palette.common.white, fontWeight: 500, p: 0 }}
                          onClick={() => handleClick("/signup")}
                        >
                          {t("buttons.signUp")}
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            color: "black",
                            fontWeight: 500,
                            bgcolor: theme.palette.secondary.main,
                            "&:hover": {
                              bgcolor: theme.palette.secondary.light,
                            },
                          }}
                          onClick={() => handleClick("/login")}
                        >
                          {t("buttons.login")}
                        </Button>
                      </>
                    )}
                    <Button sx={buttonStyles(theme)} onClick={toggleLanguage}>
                      {i18n.language === "en" ? "العربية" : "English"}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Container>
          </Toolbar>
        </Box>
      </CustomAppBar>

      <Drawer
        anchor={lang === "ar" ? "left" : "right"}
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          width: "260px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "260px",
            boxSizing: "border-box",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
        variant="temporary"
      >
        <Box sx={{ textAlign: "center", padding: "10px 0" }}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
          <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            sx={{
              flexGrow: 1,
              maxWidth: "200px",
              margin: "0 auto",
            }}
          >
            {pages.map((page, i) => (
              <Button
                key={i}
                onClick={() => handleClick(page.path)}
                sx={drawerButtonStyles(theme, page.path === activePath)}
              >
                {page.title}
              </Button>
            ))}
            {userData?.token ? (
              <>
                <Button
                  onClick={() => handleClick("/profile")}
                  sx={{
                    color: theme.palette.grey[800],
                    fontSize: "14px",
                    fontWeight: 500,
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  }}
                >
                  {t("buttons.profile")}
                </Button>
                <Button
                  sx={{
                    color: theme.palette.grey[800],
                    fontSize: "14px",
                    fontWeight: 500,
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  }}
                  onClick={handleLogout}
                >
                  {t("buttons.logout")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  sx={{
                    color: theme.palette.grey[800],
                    fontSize: "14px",
                    fontWeight: 500,
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  }}
                  onClick={() => handleClick("/signup")}
                >
                  {t("buttons.signUp")}
                </Button>
                <Button
                  sx={{
                    color: theme.palette.grey[800],
                    fontSize: "14px",
                    fontWeight: 500,
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  }}
                  onClick={() => handleClick("/login")}
                >
                  {t("buttons.login")}
                </Button>
              </>
            )}
          </Stack>
          <Button
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.main,
              mx: "auto",
              mt: 3,
            }}
            onClick={toggleLanguage}
          >
            {i18n.language === "en" ? "العربية" : "English"}
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default withTranslation()(Navbar);
