import Types from "./Types";

export const CareersLoading = (payload) => ({
  type: Types.CAREERS_LOADING,
  payload,
});

export const GetCareersSuccess = (payload) => ({
  type: Types.GET_CAREERS_SUCCESS,
  payload,
});

export const CareersRequestSuccess = (payload) => ({
  type: Types.CAREERS_REQUEST_SUCCESS ,
  payload,
});