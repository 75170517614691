  export const styles = {
    container: {
      maxWidth: { xl: "xl", md: "lg" },
      my: "70px",
    },
    title: {
      fontSize: { xs: "20px", md: "25px", lg: "36px" },
      fontWeight: { xs: 500, md: 700 },
      mb: "32px",
    },
    memberBox: {
      display: "flex",
      flexDirection: "column", 
      alignItems: "center",
      justifyContent: "center",
      padding: { xs: "20px", md: "20px" },
      borderRadius: "8px",
      gap: "10px",
      backgroundColor: "#FFFFFF",
      border: "2px solid #00000040",
      boxShadow: "none",
      maxWidth: "1280px",
      margin: "auto",
    },
    memberTitle: {
      fontSize: { xs: "16px", md: "18px", lg: "20px" },
      fontWeight: "400",
    },
    memberName: {
      fontSize: { xs: "18px", md: "22px", lg: "24px" },
      fontWeight: { xs: 500, md: 700 },
    },
  };
