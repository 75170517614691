import Types from "./Types";

export const SpecialtiesLoading = (isLoading) => ({
  type: Types.SPECIALTIES_LOADING,
  payload: isLoading,
});

export const GetSpecialitiesSuccess = (specialities) => ({
  type: Types.GET_SPECIALITIES_SUCCESS,
  payload: specialities,
});
