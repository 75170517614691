import Types from "./Types";

export const GetTeamSuccess = (partners) => ({
  type: Types.GET_TEAM_SUCCESS,
  payload: partners,
});

export const TeamLoading = (isLoading) => ({
  type: Types.TEAM_LOADING,
  payload: isLoading,
});
