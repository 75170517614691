import SuccessModal from "components/Atoms/SharedComponents/Modals/SucessModal";
import React from "react";
import { withTranslation } from "react-i18next";
import HomeTemplate from "templates/home/Home.Template";

const HomePage = ({ t, setLayoutData }) => {
  return (
    <>
      <SuccessModal t={t} />
      <HomeTemplate />;
    </>
  );
};

export default withTranslation()(HomePage);
