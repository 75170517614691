
export const styles = {
  container: {
    maxWidth: { xl: "xl", md: "lg" },
    my: { xs: "20px", sm: "100px" },
    mx: { xs: "0px", sm: "65px" },
  },
    title: {
      fontSize: { xs: "22px", sm: "48px" },
      fontWeight: { xs: "500", sm: "700" },
      textAlign: { xs: "flex-start", sm: "flex-start" },
      mb: { xs: 2, sm: 2 },
      mt: { xs: 10, sm: 4 },
    },
    formBox: {
      padding: { xs: 2, sm: 4, md: 6 },
      width:"100%",
      display: "flex",
      flexDirection: "column",
      border: "1px solid #E0E0E0",
      borderRadius: 5,
      mx: { xs: 1, sm: 0 },
      mb: { xs: 2, sm: 0 },
      position: "relative",
    },
    editIconButton: {
      position: "absolute",
      top: 8,
      left: 8,
      color: "#6c757d",
      zIndex: 1,
    },
    dataDisplay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    cityText: {
      maxWidth: '200px',  // adjust this value as needed
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    streetText: {
      maxWidth: '200px',  // adjust this value as needed
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    resumeText: {
      maxWidth: '300px',  // adjust this value as needed
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    inputLabel: {
      pb: "16px",
      fontWeight: "500",
      fontSize: { xs: "14px", sm: "22px" },
    },
    cvBox: {
      display: "flex",
      justifyContent: "center", 
      alignItems: "center",      
      border: "2px dotted #00000040",
      borderRadius: 1,
      padding: 2,
      position: "relative",
      width: "100%",
    },
    
    cvButton: {
      borderRadius: 1,
      backgroundColor: "#EEEEEE",
      position: "absolute",
      left: 15,
      top: "50%",
      transform: "translateY(-50%)",
      "&:hover": {
        backgroundColor: "#EEEEEE",
      },
    },
    cvIcon: {
      marginLeft: "8px",
      color: "#6c757d",
    },
    submitButton: {
      mt: 4,
      color: "secondary.main",
      fontWeight: 500,
      width: "100%",
      fontSize: "14px",
    },
    imageBox: {
      width: "100%",
      borderRadius: 5,
      boxShadow: 3,
      maxWidth: "527px",
      mx: "auto",
    },
    dataDisplayStack: {
      flexDirection: { xs: "column", sm: "row" },
      gap: { xs: "20px", sm: "210px" },
      mt: 2,
      mb: 3,
    },
    divider: {
      my: 2,
      width: "100%",
    },
  };
  