import React from "react";
import { Outlet } from "react-router-dom";
import { images } from "assets/AssetHelper";
import { Stack, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function AuthLayout() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      sx={{
        margin: isSmallScreen ? 1 : 10,
        height: "97vh",
        flexDirection: isSmallScreen ? "column" : "row-reverse", 
        justifyContent: isSmallScreen ? "center" : "space-between",
      }}
    >
      {/* ------------ SIDE IMAGE ------------ */}
      {!isSmallScreen && (
        <Box component={"img"} src={images.LoginSideImage} sx={{ width: "44%", height: "100%" }} />
      )}

      <Stack
        sx={{
          width: isSmallScreen ? "100%" : "50%",
          alignItems: "center",
          justifyContent: "center",
          px: isSmallScreen ? 2 : 10,
        }}
      >
        <Box sx={{ width: "100%", gap: "100px" }}>
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
}
