import Logo from "./images/Rectangle.svg";
import LoginSideImage from "./images/login-page-side-image.png";
import HeaderLogo from "./images/headerLogo.svg";
import AboutUsImage from "./images/aboutUsImg.svg"
import AboutUsFullImg from "./images/aboutUs-full.svg"
import ContactUsImg from "./images/contactUsImg.png";
import Home from "./icons/home.svg"
import User from "./icons/user.svg"
import Setting from "./icons/setting.svg"
import SearchIcon from './icons/search.svg';
import Delete from './icons/Delete-icon.svg'
// import Download from './icons/Download.svg'
import Mask from './dummy/Mask.png'
import Linkedin from "./icons/linkedin.svg";
import Twitter from "./icons/twitter.svg";
import Instagram from "./icons/instagram.svg";
import phone from "./icons/phone.svg";
import Email from "./icons/email.svg";
import Location from "./icons/location.svg";
import prevArrow from "./icons/prevArrow.svg";
import nextArrow from "./icons/nextArrow.svg";
import ProfileIcon from "./icons/profileIcn.svg";
import LogoutIcon from "./icons/logout.svg";
import EditIcon from "./icons/editIcn.svg";
import HomeIcn from "./icons/home-mobile.svg";
// import Arrow from "./icons/arrow-back.svg";
import Whatsapp from "./icons/whatsapp.svg";
import TabbyAr from "./icons/tabby-ar.svg";
import TamaraAr from "./icons/tamara-ar.svg";
import TabbyEn from "./icons/tabby-en.svg";
import TamaraEn from "./icons/tamara-en.svg";
import CareersImg from "./images/careersimg.png";
import UploadIcn from "./icons/upload-cloud.svg";
import DownloadIcn from "./icons/downloadicn.svg";
import WhatsApp from "./icons/whatsapp1.svg"
export const dummy = {
  Mask,
};

export const images = {
    Logo,
    LoginSideImage,
    HeaderLogo,
    AboutUsImage,
    AboutUsFullImg,
    ContactUsImg,
    CareersImg
}

export const icons = {
  Home,
  User,
  Setting,
  SearchIcon,
  Delete,
  // Download,
  Linkedin,
  Instagram,
  Twitter,
  Email,
  phone,
  Location,
  nextArrow,
  prevArrow,
  ProfileIcon,
  LogoutIcon,
  EditIcon,
  HomeIcn,
  // Arrow,
  Whatsapp,
  TabbyAr,
  TamaraAr,
  TabbyEn,
  TamaraEn,
  UploadIcn,
  DownloadIcn,
  WhatsApp
};





