import React from "react";
import { Modal, Box, Typography, Stack, Button, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { ToggleLogoutModal } from "services/modules/modals/Actions";
import { logoutRequest } from "services/modules/auth";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import { icons } from "assets/AssetHelper";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({ t }) => {
  const { isOpen, header, subHeader } = useSelector((state) => state.modals.logout);
  const dispatch = useDispatch();
  const load = useSelector((state) => state.auth.load);
  const isWeb = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();

  const onClose = () => {
    dispatch(
      ToggleLogoutModal({
        isOpen: false,
        header: null,
        subHeader: null,
      })
    );
  };

  const handleLogout = async () => {
    await dispatch(logoutRequest());
    navigate("/");
    onClose();
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isWeb ? "40%" : "100%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    gap: "40px",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    left: "10px",
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <IconButton sx={closeButtonStyle} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography fontSize={{ xs: "16px", sm: "32px" }} textAlign="center" fontWeight="700" sx={{ mb: 2 }}>
          {header}
        </Typography>
        <Stack direction="row" alignItems="center" gap="10px" justifyContent="center" sx={{ mb: 4 }}>
          <img src={icons.LogoutIcon}  alt="Logout Icon" style={{ width: 20, height: 20 }}/> 
          <Typography fontSize={{ xs: "14px", sm: "26px" }} textAlign="center" fontWeight="400">
            {subHeader}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={2} justifyContent="center">
          <LoadingBtn
            variant="contained"
            sx={{ color: "secondary.main" , fontSize: { xs: "14px", sm: "26px" } }}
            onClick={handleLogout}
            isLoading={load?.loading && load?.type === "logout"}
          >
            {t("buttons.confirm")}
          </LoadingBtn>
          <Button
            variant="outlined"
            sx={{  fontSize:{ xs: "14px", sm: "26px" } }}
            onClick={onClose}
          >
            {t("buttons.cancel")}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
