import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Stack,
  Container,
  useMediaQuery,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { icons, images } from "assets/AssetHelper";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import LoadingBtn from "components/Atoms/Loader/LoadingBtn";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Close as CloseIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import { useValidationSchemas } from "common/utils/schemas";
import { GetCareersRequest, SubmitCareersRequest } from "services/modules/careers";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import setAuthToken from "services/modules/utils/handel_api";
import { styles } from "./Styles";

const Careers = () => {
  const token = useSelector((state) => state.auth.userData.token);
  const { careersSchema } = useValidationSchemas();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [fileName, setFileName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // State to track file upload

  const { userData, isLoading } = useSelector((state) => state.careers);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      dispatch(GetCareersRequest({}));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userData.city && userData.street && userData.resume) {
      setSubmittedData(userData);
      setFileName(userData.resume.split("/").pop());
      setIsSubmitted(true);
      formik.setValues({
        city: userData.city,
        area: userData.street,
        resume: userData.resume,
      });
    }
  }, [userData]);

  const handleEdit = () => {
    setIsSubmitted(false);
  };

  const uploadFile = (e) => {
    const extension = e?.target.files[0]?.type?.slice(e?.target.files[0]?.type.indexOf("/") + 1);
    const selectedFileName = e?.target.files[0]?.name;
    setFileName(selectedFileName);
    setIsUploading(true); // Set loading state to true

    dispatch(
      getPresignedUrlRequest({
        body: {
          file: e.target.files[0],
          params: {
            extension,
          },
        },
        updateData: (fileUrl) => {
          formik.setValues({ ...formik.values, resume: fileUrl });
          setIsUploading(false); // Set loading state to false after upload
        },
      })
    );
  };

  const handleFileRemove = () => {
    setFileName("");
    formik.setFieldValue("resume", null);
  };

  const formik = useFormik({
    initialValues: {
      city: "",
      area: "",
      resume: "",
    },
    validationSchema: careersSchema,
    onSubmit: (values) => {
      const requestData = {
        body: {
          user: {
            city: values.city,
            street: values.area,
            resume: values.resume,
          },
        },
      };

      dispatch(SubmitCareersRequest(requestData)).then(() => {
        setSubmittedData(values);
        setIsSubmitted(true);
        dispatch(GetCareersRequest({})); 
      });
    },
  });

  return (
    <>
      <Container sx={styles.container}>
        <Typography gutterBottom sx={styles.title}>
          {t("home.careers.title")}
        </Typography>
        <Grid
          container
          spacing={5}
          direction={isSmallScreen ? "column" : "row"}
          alignItems="center"
        >
          {isSmallScreen && (
            <Grid item xs={12}>
              <Box component="img" src={images.CareersImg} alt="Careers" sx={styles.imageBox} />
            </Grid>
          )}
          <Grid item xs={12} md={6.5}>
            {isSubmitted && submittedData ? (
              <Box sx={styles.formBox}>
                <IconButton sx={styles.editIconButton} onClick={handleEdit}>
                  <img src={icons.EditIcon} alt="edit" />
                </IconButton>
                <Stack direction={isSmallScreen ? "column" : "row"} mt={2} mb={3}>
                  <Stack direction="column" mb={2} flex={1}>
                    <Typography sx={styles.inputLabel}>{t("careers.city")}</Typography>
                    <Typography sx={styles.cityText}>
                      {submittedData.city}
                    </Typography>
                  </Stack>
                  <Stack direction="column" mb={2} flex={1}>
                    <Typography sx={styles.inputLabel}>{t("careers.area")}</Typography>
                    <Typography sx={styles.streetText}>
                      {submittedData.street}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={styles.dataDisplay}
                  onClick={() => window.open(submittedData.resume, "_blank")}
                >
                  <Typography sx={styles.inputLabel}>{t("careers.cv")}</Typography>
                  <Box sx={styles.cvBox}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ width: "100%", justifyContent: "space-between" }}
                    >
                      <Typography sx={styles.resumeText}>{fileName}</Typography>
                      <img src={icons.DownloadIcn} alt="download" style={styles.cvIcon} />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Box sx={styles.formBox}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={styles.inputLabel}>{t("careers.city")}</Typography>
                      <TextFieldInput
                        fullWidth
                        variant="outlined"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={styles.inputLabel}>{t("careers.area")}</Typography>
                      <TextFieldInput
                        fullWidth
                        variant="outlined"
                        name="area"
                        value={formik.values.area}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.area && Boolean(formik.errors.area)}
                        helperText={formik.touched.area && formik.errors.area}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={styles.inputLabel}>{t("careers.cv")}</Typography>
                      <Box sx={styles.cvBox}>
                        {!formik.values.resume ? (
                          <>
                            <Button component="label" variant="outlined" sx={styles.cvButton}>
                              {isUploading ? (
                                <CircularProgress size={24} />
                              ) : (
                                t("buttons.choose")
                              )}
                              <input type="file" hidden onChange={uploadFile} />
                            </Button>
                            {!isSmallScreen && (
                              <Box
                                component="img"
                                src={icons.UploadIcn}
                                alt="Resume"
                                sx={styles.cvIcon}
                              />
                            )}
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ width: "100%", textAlign: "center", ml: 10 }} 
                            >
                              <Typography variant="body2">{t("careers.cv")}</Typography>
                              <Typography variant="caption" color="textSecondary">
                                {t("careers.pdf")}
                              </Typography>
                            </Stack>
                          </>
                        ) : (
                          <>
                            <Typography variant="body2" sx={{ flexGrow: 1 }}>
                              {fileName}
                            </Typography>
                            <CloseIcon
                              onClick={handleFileRemove}
                              sx={{ cursor: "pointer", color: "#6c757d" }}
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Stack justifyContent="center" alignItems="center" spacing={2}>
                    <LoadingBtn
                      type="submit"
                      variant="contained"
                      sx={styles.submitButton}
                      onClick={formik.handleSubmit}
                    >
                      {t("buttons.send")}
                    </LoadingBtn>
                  </Stack>
                </Box>
              </form>
            )}
          </Grid>
          {!isSmallScreen && (
            <Grid item xs={12} md={5}>
              <Box component="img" src={images.CareersImg} alt="Careers" sx={styles.imageBox} />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Careers;
