import Types from "./Types";

export const ContactUsLoading = (isLoading) => ({
  type: Types.CONTACT_US_LOADING,
  payload: isLoading,
});

export const GetContactUsSuccess = (services) => ({
  type: Types.GET_CONTACT_US_SUCCESS,
  payload: services,
});


