import { AppBar, Box, Button, Drawer, styled } from "@mui/material";

export const CustomAppBar = styled(AppBar)(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const appBarStyles = (theme, isSmallScreen) => ({
  boxShadow: "none",
  bgcolor: isSmallScreen ? theme.palette.common.white : theme.palette.common.black,
  color: isSmallScreen ? theme.palette.text.primary : theme.palette.common.white,
  transition: theme.transitions.create(["background-color", "color"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

export const buttonStyles = (theme, isActive) => ({
  my: 2,
  color: isActive ? theme.palette.secondary.main : "white",
  fontWeight: 500,
  fontSize: "19px",
  display: "block",
  transition: "color 0.3s ease",
  "&:hover": {
    color: theme.palette.secondary.main,
  },
  "&:active": {
    color: theme.palette.secondary.main,
  },
});

export const drawerButtonStyles = (theme, isActive) => ({
  color: isActive ? theme.palette.secondary.main : theme.palette.grey[800],
  fontSize: "14px",
  fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  transition: "color 0.3s ease",
  "&:hover": {
    color: theme.palette.secondary.main,
  },
  "&:active": {
    color: theme.palette.secondary.main,
  },
});

export const profileMenuStyles = (theme) => ({
  paperProps: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
  transformOrigin: { horizontal: "right", vertical: "top" },
  anchorOrigin: { horizontal: "right", vertical: "bottom" },
});

// export const footerStyles = (isWeb) => ({
//   container: {
//     backgroundColor: "#000",
//     color: "#fff",
//     padding: { xs: "10px", lg: "50px" },
//   },
//   innerContainer: {
//     maxWidth: { xl: "xl", md: "lg" },
//   },
//   stackMain: {
//     direction: isWeb ? "row" : "column",
//     justifyContent: "space-between",
//     alignItems: "start",
//     spacing: 2,
//   },
//   logoStack: {
//     width: { xs: "100%", md: "33%" },
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   logoImage: {
//     width: { xs: "150px", md: "269px" },
//   },
//   description: {
//     fontWeight: 400,
//     fontSize: { xs: "12px", sm: "20px" },
//   },
//   socialIconsStack: {
//     direction: "row",
//     sx: { width: "100%", pt: "20px" },
//     gap: 2,
//   },
//   footerIconButton: {
//     minWidth: 0,
//     padding: 0,
//     color: "#fff",
//   },
//   pagesAndContactStack: {
//     direction: "row",
//     width: { xs: "100%", md: "55%" },
//     gap: isWeb ? "150px" : "30px",
//   },
//   pagesStack: {
//     width: { xs: "100%", md: "auto" },
//     alignItems: "flex-start",
//     justifyContent: "center",
//     gap: "10px",
//   },
//   pageButton: {
//     textTransform: "none",
//     color: "white",
//     fontSize: { xs: "12px", sm: "20px" },
//     p: 0,
//   },
//   contactStack: {
//     width: { xs: "100%", sm: "auto" },
//     gap: "30px",
//   },
//   contactTitle: {
//     fontSize: { xs: "14px", sm: "20px" },
//     fontWeight: "500",
//     color: "white",
//   },
//   contactInfoStack: {
//     gap: { xs: "10px", sm: "38px" },
//   },
//   contactItem: {
//     direction: "row",
//     alignItems: "center",
//   },
//   contactIcon: {
//     color: "secondary.main",
//   },
//   contactText: {
//     px: 2,
//     fontSize: { xs: "12px", sm: "20px" },
//     color: "#fff",
//   },
//   mobileSocialIconsStack: {
//     width: "100%",
//     direction: "row",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: 1,
//   },
// });



export const menuPaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export const NavbarContainer = styled(Box)({
  width: "100%",
});

export const ToolbarStyled = styled(Box)(({ theme }) => ({
  paddingRight: 0,
  paddingLeft: 0,
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

export const NavbarButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: 0,
  margin: "0 !important",
  fontWeight: 500,
  fontSize: theme.breakpoints.down("md") ? "14px" : "18px",
  whiteSpace: "nowrap",
  "&:hover": {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: "transparent",
  },
}));

export const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: "260px",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "260px",
    boxSizing: "border-box",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export const DrawerButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "14px",
  fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  "&:hover": {
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
  },
}));
