import { Container } from "@mui/material";
import Header from "components/Atoms/SharedComponents/Header";
import Services from "components/Organisms/services/Services";
import React from "react";

export default function ServicesTemplate({ servicesData, t }) {
  return (
    <>
      <Header 
     title={t("header.services.title")}
     subtitle={t("header.services.subtitle")}
    />
    <Services/>
    </>
  );
}
