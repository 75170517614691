// import languageReducer from './Reducer';

import { setLanguage } from "./Actions";

// export default languageReducer;

export const setCurrentLanguage = (language) => (dispatch) => {
  localStorage.setItem("i18nextLng", language);
  dispatch(setLanguage(language));
  setTimeout(() => {
    window.location.reload();
  });
};
