import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TogglePrivacyModal } from 'services/modules/modals/Actions'; 
import { GetPrivacyRequest } from 'services/modules/termsAndConditions';

const PrivacyModal = () => {
  const dispatch = useDispatch();
  const { isOpen, header, message } = useSelector((state) => state.modals.privacy);
  const { articles } = useSelector((state) => state.termsAndConditions); 
  const isWeb = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    if (isOpen) {
      dispatch(GetPrivacyRequest({ article: 'privacy_policy' }));
    }
  }, [dispatch, isOpen]);

  const handleClose = () => {
    dispatch(TogglePrivacyModal({
      isOpen: false,
      header: "",
    }));
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isWeb ? "40%" : "80%",
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
  };

  const contentStyle = {
    flex: 1,
    overflowY: 'auto',
    maxHeight: '70vh', 
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <IconButton sx={closeButtonStyle} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography fontSize={{ xs: "18px", sm: "32px" }} fontWeight="700" textAlign="center" sx={{ m:2 }}>
          {articles?.name}
        </Typography>
        <Box sx={contentStyle}>
          <Typography fontSize={{ xs: "16px", sm: "26px" }} fontWeight="400" sx={{ mt: 2, textAlign: 'justify' }}>
            <div dangerouslySetInnerHTML={{ __html: articles?.article }} />
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrivacyModal;
