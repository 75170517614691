import { makeFilterString } from "../utils/Helper";
import { GetAboutUsSuccess, AboutUsLoading, GetFullAboutUsSuccess } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetArticlesRequest = ({...variables}) => {
  return async (dispatch) => {

    const requestData = {
      isLoading: AboutUsLoading,
      successFunction: GetAboutUsSuccess,
      loadingType: "list articles",
      url: `/documents/${variables?.article}${makeFilterString(variables?.params)}`,
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetFullArticlesRequest = ({...variables}) => {
  return async (dispatch) => {

    const requestData = {
      isLoading: AboutUsLoading,
      successFunction: GetFullAboutUsSuccess,
      loadingType: "list articles",
      url: `/documents/${variables?.article}`,
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
