import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Container, Typography, useMediaQuery, Button } from "@mui/material";
import { GetArticlesRequest } from "services/modules/aboutUs";
import AboutUsArticle from "components/Molecules/Home/AboutUsArticle";
import { icons, images } from "assets/AssetHelper";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const dispatch = useDispatch();
  const { articles } = useSelector((state) => state.aboutUs);
  const isWeb = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const handleReadMore = () => {
    navigate("/about-us");
  };
  const getArticles = () => {
    const requestData = {
      params: { db: "almasah" },
      article: "home_about",
    };
    dispatch(GetArticlesRequest(requestData));
  };
  useEffect(() => {
    getArticles();
  }, [dispatch]);

  return (
    <Container sx={{ maxWidth: { xl: "xl", md: "lg" }, mt: { xs: "80px", sm: 0 } }}>
      {/* Breadcrumb */}
      {!isWeb && location.pathname === "/" && (
        <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
          <img src={icons.HomeIcn} alt="home Icon" style={{ width: 24, height: 24 }} />
          <Typography fontSize="20px" fontWeight="400" sx={{ mx: 1 }}>
            /
          </Typography>
          <Typography fontSize="22px" fontWeight="500" sx={{ ml: 1 }}>
            {t("home.pages.home")}
          </Typography>
        </Box>
      )}

      <Stack
        width="100%"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        gap="22px"
        mt={5}
      >
        {!isWeb && (
          <Stack component="figure" sx={{ flex: 1, fontSize: 0, width: { xs: "100%", sm: "50%" } }}>
            <Box
              component="img"
              src={images.AboutUsImage}
              alt="About Us"
              sx={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Stack>
        )}

        <Stack sx={{ flex: 2, width: { xs: "100%", sm: "50%" } }}>
          <AboutUsArticle article={articles} />
        </Stack>

        {isWeb && (
          <Stack component="figure" sx={{ flex: 2, fontSize: 0, width: { xs: "100%", sm: "50%" } }}>
            <Box
              component="img"
              src={images.AboutUsImage}
              alt="About Us"
              sx={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Stack>
        )}
      </Stack>

      {!isWeb && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: 21,
            }}
            onClick={handleReadMore}
          >
            {t("buttons.readMore")}
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default AboutUs;
