import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AboutUsFullArticle = ({ article }) => {
    const { t } = useTranslation();

    return (
        <Stack
            sx={{
                justifyContent: "space-between",
                height: "100%",
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Typography fontSize={{ xs: "22px", md: "32px" }} fontWeight="500" gutterBottom>
                    {article?.name}
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: article.article }} />
            </Box>

        </Stack>
    );
};

export default AboutUsFullArticle;
