import { makeFilterString } from "../utils/Helper";
import { GetAboutUsSuccess, AboutUsLoading, ConditionLoading, GetTermsSuccess, GetPrivacySuccess } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";


export const GetTermsRequest = ({...variables}) => {
  return async (dispatch) => {

    const requestData = {
      isLoading: ConditionLoading,
      successFunction: GetTermsSuccess,
      loadingType: "list terms",
      url: `/documents/${variables?.article}`,
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetPrivacyRequest = ({...variables}) => {
  return async (dispatch) => {

    const requestData = {
      isLoading: ConditionLoading,
      successFunction: GetPrivacySuccess,
      loadingType: "list privacy",
      url: `/documents/${variables?.article}`,
      method: "get",
    };

    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};