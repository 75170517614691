export const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
     my:"70px",
    },
    profileSection: {
      flex: 2,
      boxShadow: 3,
      borderRadius: 2,
      border: "1px solid #ddd",
    },
    profileSectionHeader: {
      bgcolor: "secondary.main",
      p: 2,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    profileSectionContent: {
      gap:"100px",
      p: 3,
    },
    profileTitle: {
      fontSize: "20px",
    },
    profileText: {
      fontSize: 20,
      mt: 1,
    },
    editIcon: {
      fontSize: "20px",
    },
    editButton: {
      fontSize: "15px",
      fontWeight: "500",
    },
    settingsSection: {
      flex: 0.75,
      boxShadow: 3,
      borderRadius: 2,
      border: "1px solid #ddd",
    },
    settingsSectionHeader: {
      bgcolor: "secondary.main",
      p: 2,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    settingsSectionContent: {
      p: 3,
      gap:"50px"
    },
    button: {
      fontSize: "20px",
    },
    buttonMobile: {
      fontSize: "16px",
      textDecoration: "underline",
      textUnderlineOffset: "0.2em",
    },
    divider: {
      mt: 2,
    },
  };
  