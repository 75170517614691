import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "pages/home/Home.Page.jsx";
import React, { useEffect, useState } from "react";
import Layout from "components/Organisms/layout";
import AuthLayout from "components/Organisms/auth/layout/AuthLayout";
import LoginPage from "pages/auth/loginPage";
import SignUpPage from "pages/auth/signupPage";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import setAuthToken from "services/modules/utils/handel_api";
import ServicesPage from "pages/services/Services.Page";
import AboutUsPage from "pages/aboutus/AboutUs.page";
import ContactUsPage from "pages/contactus/ContactUs.page";
import TeamWorkPage from "pages/teamwork/TeamWork.Page";
import ProfilePage from "pages/profile/Profile.Page";
import BooksPage from "pages/books/Books.Page";
import LinksPage from "pages/links/Links.Page";
import CareersPage from "pages/careers/Careers.page";

const AppRoute = () => {
  const [layoutData, setLayoutData] = useState(null);
  const token = useSelector((state) => state.auth.userData.token);
  setAuthToken(token);
  const lang = useSelector((state) => state.language.lang);

  const ProtectedRoute = ({ children }) => {
    return token ? children : <Navigate to="/login" />;
  };

  const PublicRoute = ({ children }) => {
    return token ? <Navigate to="/" /> : children;
  };
  return (
    
    <Routes>
      <>
        <Route path="/" element={<Layout layoutData={layoutData} />}>
          <Route index element={<HomePage setLayoutData={setLayoutData} />} />
          <Route path="/legal-services/" element={<ServicesPage/>} />
          <Route path="/about-us/" element={<AboutUsPage/>} />
          <Route path="/contact-us/" element={<ContactUsPage/>} />
          <Route path="/team-work/" element={<TeamWorkPage/>} />
          <Route path="/our-books/" element={<BooksPage/>} />
          <Route path="/important-links/" element={<LinksPage/>} />
          <Route path="/careers/" element={<CareersPage/>} />

          {/* <Route path="/profile/" element={<ProfilePage/>} /> */}

          <Route
            path="/profile/"
            element={   
              <ProtectedRoute>
                 <ProfilePage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/" element={<AuthLayout />}>
          <Route
            path="login"
            index
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
        </Route>
        <Route path="*" element={<p>Not found</p>} />
        <Route path="/" element={<AuthLayout />}>
          <Route
            path="signup"
            index
            element={
              <PublicRoute>
                <SignUpPage />
              </PublicRoute>
            }
          />
        </Route>
        <Route path="*" element={<p>Not found</p>} />
      </>
    </Routes>
  );
};

export default AppRoute;
