import Types from "./Types";

export const GetBooksSuccess = (payload) => ({
  type: Types.GET_BOOKS_SUCCESS,
  payload,
});

export const BooksLoading = (payload) => ({
  type: Types.BOOKS_LOADING,
  payload,
});
