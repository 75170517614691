import { withTranslation } from "react-i18next";
import CareersTemplate from "templates/careers/Careers.Template";

const CareersPage = ({ t }) => {
  return (
    <>
      <CareersTemplate t={t} />
    </>
  );
};

export default withTranslation()(CareersPage);
