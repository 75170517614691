import { withTranslation } from "react-i18next";
import ServicesTemplate from "templates/services/Services.Template";

const ServicesPage = ({ t }) => {

  return (
    <>
      
        <ServicesTemplate  t={t} />
  
    </>
  );
};

export default withTranslation()(ServicesPage);
