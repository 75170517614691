import { withTranslation } from "react-i18next";
import LinksTemplate from "templates/links/Links.Template";

const LinksPage = ({ t }) => {
  return (
    <>
      <LinksTemplate t={t} />
    </>
  );
};

export default withTranslation()(LinksPage);
