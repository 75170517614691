import Header from "components/Atoms/SharedComponents/Header";
import Books from "components/Organisms/books/Books";
import React from "react";

export default function BooksTemplate({  t }) {
  return (
    <>
      <Header 
     title={t("header.books.title")}
     subtitle={t("header.books.subtitle")}
    />
    <Books/>    
    </>
  );
}
